import React, { Component, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Container, Nav } from 'reactstrap';
import { withRouter } from 'react-router';
import { Collapse, Button } from "reactstrap";
import {
	AppAside,
	AppFooter,
	AppHeader,
	AppSidebar,
	AppSidebarFooter,
	AppSidebarForm,
	AppSidebarMinimizer,
	AppSidebarNav,
} from '@coreui/react';
import Dwell from "../../../PowerYard/components/AssetDwell"
import Arrival from "../../../Arrival/components/Arrival";
import ArrivalGate from "../../../PowerYard/components/Arrival";
import ArrivalHistory from "../../../PowerYard/components/ArrivalHistory";
import Departure from "../../../Departure/components/Departure";
import Assets from "../../../assets/components/Assets";
import Admin from "../../../Admin/components/Admin";
import connect from "react-redux/es/connect/connect";
import {
	ROLE_ACCOUNTS_PAYABLE,
	ROLE_ADMIN,
	ROLE_ATTENDANT,
	ROLE_CARRIER_ACCOUNTS_PAYABLE,
	ROLE_CARRIER_ADMIN,
	ROLE_CARRIER_DISPATCHER,
	ROLE_E4SCORE_ADMIN,
	ROLE_OPERATOR,
	ROLE_POWERYARD_ADMIN,
	ROLE_POWERYARD_JOCKEY,
	ROLE_POWERYARD_MANAGER,
	ROLE_POWERYARD_REQUESTER,
	ROLE_TRACKASSURED_ADMIN,
	ROLE_TRACKASSURED_USER,
	ROLE_EZTRACK_ADMIN,
	ROLE_EZTRACK_MANAGER,
	ROLE_EZTRACK_USER,
	userHasRole,
	isLocationRequired,
	ROLE_GATE_USER
} from "../../../Roles";
import ShipmentProfile from "../../../Shipment/components/ShipmentProfile";
import DispatchEditor from "../../../Dispatch/components/DispatchEditor";
import DispatchAssets from "../../../Dispatch/components/DispatchAssets";
import {
	downloadDispatchAssessorialsForTable,
	downloadDispatchAssetsForTable,
	downloadEZTrackAssetEvents,
	downloadEZTrackDevicesForTable,
	downloadPingAssetsForTable,
	// searchLocations
} from "../../../NetworkHelpers";
import AssetsHistoryComponent from "../../../Dispatch/components/AssetsHistoryComponent";
import EZTrackAssets from "../../../PingAssets/components/EZTrackAssets";
import EZTrackAssetProfile from "../../../PingAssets/components/EZTrackAssetProfile";
import EZTrackMap from "../../../PingAssets/components/EZTrackMap";
import EZTrackEvents from "../../../PingAssets/components/EZTrackEvents";
import PowerYardSettings from "../../../PowerYard/components/PowerYardSettings";
import EZCheckInSettings from "../../../Admin/components/EZCheckInSettings";
import Tasks from "../../../PowerYard/components/Tasks";
import { storeCurrentLocation, storeCurrentShift, storeCurrentUser, storeDisableScreen } from "../../../Login/actions/Login";
import { GPSCoordinatedWrapper } from "../../../common/GPSCoordianteWrapper";
import ErrorBoundary from '../../../common/BoundaryErrorWrapper';

import TaskEdit from "../../../PowerYard/components/TaskEdit";
import TaskInProgress from "../../../PowerYard/components/TaskInProgress";
import { showError, showInfo } from '../../../MainApp';

import TaskHistory from "../../../PowerYard/components/TaskHistory";
import YardCheckHistory from "../../../PowerYard/components/YardCheckHistory";
import { getLocalStorageName } from "../../../common/utilities";
import ShipmentDispatches from "../../../Dispatch/components/ShipmentDispatches";
import DispatchDispatches from "../../../Dispatch/components/DispatchDispatches";
import ShipmentsTA from '../../../TrackAssured/components/ShipmentsTA';
import ShipmentTAProfile from '../../../TrackAssured/components/ShipmentTAProfile';
import Onsite from '../../../PowerYard/components/Onsite';
import AssetForm from '../../../PowerYard/components/AssetForm';
// // sidebar nav config
// import navigation from '../../_nav';
// // routes config
// import routes from '../../routes';
// ErrorBoundary.js




const DefaultAside = React.lazy(() => import('./DefaultAside'));

const DefaultFooter = React.lazy(() => import('./DefaultFooter'));
const DefaultHeader = React.lazy(() => import('./DefaultHeader'));
const DefaultHeaderTA = React.lazy(() => import('../../../TrackAssured/components/shared/DefaultHeader'));
const DefaultFooterTA = React.lazy(() => import('../../../TrackAssured/components/shared/DefaultFooter'));



class DefaultLayout extends Component {

	loading = () => <div className="animated fadeIn pt-1 text-center">Loading...</div>;
	TASKS_PAGE_ROLES = [ROLE_E4SCORE_ADMIN, ROLE_POWERYARD_ADMIN, ROLE_POWERYARD_MANAGER, ROLE_POWERYARD_JOCKEY, ROLE_POWERYARD_REQUESTER];
	ONSITE_ROLES = [ROLE_E4SCORE_ADMIN, ROLE_POWERYARD_ADMIN, ROLE_POWERYARD_MANAGER, ROLE_POWERYARD_REQUESTER, ROLE_POWERYARD_JOCKEY];
	DWELL_PAGE_ROLES = [ROLE_E4SCORE_ADMIN, ROLE_POWERYARD_ADMIN, ROLE_POWERYARD_MANAGER, ROLE_POWERYARD_JOCKEY, ROLE_POWERYARD_REQUESTER];
	DISPATCH_PAGE_ROLES = [ROLE_E4SCORE_ADMIN, ROLE_CARRIER_ADMIN, ROLE_CARRIER_ACCOUNTS_PAYABLE, ROLE_CARRIER_DISPATCHER, ROLE_OPERATOR, ROLE_ATTENDANT, ROLE_ACCOUNTS_PAYABLE, ROLE_ADMIN];
	DISPATCH_CREATE_ROLES = [ROLE_E4SCORE_ADMIN, ROLE_CARRIER_ADMIN, ROLE_ADMIN];
	ASSETS_PAGE_ROLES = [ROLE_ADMIN, ROLE_ACCOUNTS_PAYABLE, ROLE_ATTENDANT, ROLE_OPERATOR];
	SHIPMENTS_PAGE_ROLES = [ROLE_ADMIN, ROLE_ACCOUNTS_PAYABLE, ROLE_OPERATOR, ROLE_ATTENDANT, ROLE_CARRIER_DISPATCHER, ROLE_CARRIER_ACCOUNTS_PAYABLE, ROLE_CARRIER_ADMIN];
	DISPATCH_ASSETS_PAGE_ROLES = [ROLE_ADMIN, ROLE_ACCOUNTS_PAYABLE, ROLE_CARRIER_ADMIN, ROLE_CARRIER_ACCOUNTS_PAYABLE, ROLE_CARRIER_DISPATCHER];
	ASSESSORIALS_PAGE_ROLES = [ROLE_ADMIN, ROLE_ACCOUNTS_PAYABLE];
	ASSETS_HISTORY_PAGE_ROLES = [ROLE_ADMIN, ROLE_ACCOUNTS_PAYABLE, ROLE_CARRIER_ADMIN, ROLE_CARRIER_ACCOUNTS_PAYABLE, ROLE_CARRIER_DISPATCHER];
	ARRIVAL_PAGE_ROLES = [ROLE_ADMIN, ROLE_ATTENDANT, ROLE_E4SCORE_ADMIN];
	ARRIVALHISTORY_PAGE_ROLES = [ROLE_ADMIN, ROLE_ATTENDANT, ROLE_E4SCORE_ADMIN];
	// ADMINISTRATION_PAGE_ROLES = [ROLE_ADMIN, ROLE_E4SCORE_ADMIN, ROLE_CARRIER_ADMIN, ROLE_POWERYARD_ADMIN, ROLE_POWERYARD_MANAGER, ROLE_TRACKASSURED_ADMIN, ROLE_EZTRACK_ADMIN, ROLE_EZTRACK_MANAGER];
	ADMINISTRATION_PAGE_ROLES = [ROLE_ADMIN, ROLE_E4SCORE_ADMIN, ROLE_CARRIER_ADMIN, ROLE_POWERYARD_ADMIN, ROLE_TRACKASSURED_ADMIN, ROLE_EZTRACK_ADMIN, ROLE_POWERYARD_MANAGER, ROLE_EZTRACK_MANAGER];
	EZCIN_WELCOME_CTR_ROLES = [ROLE_CARRIER_ACCOUNTS_PAYABLE, ROLE_CARRIER_ADMIN, ROLE_CARRIER_DISPATCHER, ROLE_E4SCORE_ADMIN];
	EZCIN_DISPATCH_ROLES = [ROLE_ACCOUNTS_PAYABLE, ROLE_ATTENDANT, ROLE_ADMIN, ROLE_OPERATOR, ROLE_E4SCORE_ADMIN];
	POWER_YARD_ROLES = [ROLE_POWERYARD_REQUESTER, ROLE_POWERYARD_JOCKEY, ROLE_POWERYARD_MANAGER, ROLE_POWERYARD_ADMIN, ROLE_E4SCORE_ADMIN];
	TRACKASSURED_ROLES = [ROLE_TRACKASSURED_USER, ROLE_TRACKASSURED_ADMIN, ROLE_E4SCORE_ADMIN];
	EZTRACK_ROLES = [ROLE_EZTRACK_ADMIN, ROLE_EZTRACK_MANAGER, ROLE_EZTRACK_USER, ROLE_E4SCORE_ADMIN];

	signOut(e) {
		e.preventDefault();
		this.props.logout();
	}
	constructor(props) {
		super(props);
		this.state = {
			isLocationRequired:false,
			disabledProgress: false,
			appSidebarDisplay: 'lg',
			appSidebarMinimized: false,
			vantixCheck: false,
			tablet: false,
			openCollapse: false
			// allLocations: []
		}
		let savedState = JSON.parse(localStorage.getItem(getLocalStorageName("default-layout", props.user)));
		if (savedState) {
			this.state = savedState;
		}
		if (this.state.appSidebarDisplay === undefined) {
			this.state.appSidebarDisplay = 'lg';
		}
		if (this.state.appSidebarMinimized === undefined) {
			this.state.appSidebarMinimized = false;
		}
		if (userHasRole(props.user, ROLE_POWERYARD_JOCKEY) && props.user.roles.length === 1) {
			this.state.appSidebarDisplay = '';
		}
		// let searchRequest = { "page": 0, "pageSize": 100, "sorted": [], "filtered": [] };
		// searchLocations(searchRequest,
		//     (response) => {
		//         this.setState({ allLocations: response.content });

		//     }); 
		let url = window.location.origin;

		if (url.includes("vantix")) {
			let s = { ... this.state };
			s.vantixCheck = true;
			this.state = s;
		} else {
			let s = { ... this.state };
			s.vantixCheck = false;
			this.state = s;
		}

	}

	componentDidMount() {
		console.log("componentDidMount	callback)",this.props.user.roles)
		this.setState({ isLocationRequired: isLocationRequired(this.props.user.roles) });
		window.scrollTo(0, 0);
		if (this.props.user.company.enableEzTrack && this.props.location.pathname === "/") {
			this.props.history.push("/eztrack-map");
			// } else if (this.props.user.company.enablePowerYard && this.props.location.pathname === "/" ) {
		} else if (this.props.user.company.enablePowerYard && this.props.location.pathname === "/" && userHasRole(this.props.user, [ROLE_E4SCORE_ADMIN, ROLE_POWERYARD_ADMIN, ROLE_POWERYARD_MANAGER, ROLE_POWERYARD_REQUESTER, ROLE_POWERYARD_JOCKEY])) {
			this.props.history.push("/poweryard-tasks");

		} else if (this.props.user.company.enablePowerYard && (this.props.location.pathname === "/login-poweryard" || this.props.location.pathname === "/") && userHasRole(this.props.user, [ROLE_GATE_USER])) {
			this.props.history.push("/poweryard-arrival");
		}
		else if (this.props.user.company.enablePowerYard && this.props.location.pathname === "/login-poweryard") {
			this.props.history.push("/poweryard-tasks");
		}
		else if (this.props.user.company.enableEzTrack && this.props.location.pathname === "/") {
			this.props.history.push("/shipments");
		} else if (this.props.user.company.enableTrackAssured) {
			if (this.props.location.pathname === "/login-trackassured") {
				this.props.history.push("/shipments-trackassured");
			} else if (this.props.location.pathname === "/") {
				this.props.history.push("/shipments-trackassured");
			}
		} else if (userHasRole(this.props.user, this.ADMINISTRATION_PAGE_ROLES) && this.props.location.pathname === "/") {
			this.props.history.push("/admin");
		}
		window.addEventListener('click', this.handleClick.bind(this));
		//this.setState({disabledProgress:localStorage.getItem('DisableScreen')});
		console.log("disablecreen");
		const userAgent = navigator.userAgent.toLowerCase();
		const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
		// console.log(isTablet)
		if (isTablet === true) {
			this.setState({
				tablet: true
			});
		} else {
			this.setState({
				tablet: false
			});
		}
	}

	componentWillUnmount() {
		localStorage.setItem(getLocalStorageName('default-layout', this.props.user), JSON.stringify(this.state));
		window.removeEventListener('click', this.handleClick.bind(this));
	}

	handleClick(event) {
		if (event != null && event.target && (event.target.id === 'app-sidebar-toggler' || (event.target.parentElement && event.target.parentElement.id === 'app-sidebar-toggler'))) {
			if (this.state.appSidebarDisplay === 'lg') {
				this.setState({ appSidebarDisplay: '' });
			} else {
				this.setState({ appSidebarDisplay: 'lg' });
			}

			localStorage.setItem(getLocalStorageName('default-layout', this.props.user), JSON.stringify(this.state));
			event.preventDefault();
		} else if (event != null && (event.target.id === 'app-sidebar-minimizer' || (event.target.parentElement && event.target.parentElement.id === 'app-sidebar-minimizer'))) {
			// let resizeEvent = new Event('resize');
			this.setState({ appSidebarMinimized: !this.state.appSidebarMinimized });
			localStorage.setItem(getLocalStorageName('default-layout', this.props.user), JSON.stringify(this.state));
			event.preventDefault();
			// window.dispatchEvent(resizeEvent);
		}
	}
	trackAssuredavigation() {
		let navigation = {
			items: []
		};

		if (this.props.user.company.enableTrackAssured && userHasRole(this.props.user, this.TRACKASSURED_ROLES)) {
			navigation.items.push({
				title: true,
				name: 'TrackAssured',
				wrapper: {
					element: 'div',
					attributes: {
						style: {
							fontFamily: "corbel,sans-serif",
							color: '#30b1a1',
							fontSize: 24,
							fontWeight: 'bold',
							fontStyle: 'italic',
							textTransform: 'none',
							textAlign: 'left',
						}
					}
				}
			});
			navigation.items.push({
				name: <span title="TrackAssured Shipments">Tracked Shipments</span>,
				url: '/shipments-trackassured',
				exact: true,
				icon: 'fa fa-boxes fa-lg'
			});
		}


		// if (userHasRole(this.props.user, [ROLE_E4SCORE_ADMIN, ROLE_POWERYARD_ADMIN, ROLE_POWERYARD_MANAGER])) {
		//     navigation.items.push({
		//         name: <span title="Table with history of Tasks">Task History</span>,
		//         url: '/poweryard-task-history',
		//         exact: true,
		//         icon: 'fa fa-tasks fa-lg'
		//     });
		// }
		navigation.items.push({
			divider: true,
		});
		return navigation;
	};

	powerYardNavigation() {
		let navigation = {
			items: []
		};

		if (this.props.user.company.enablePowerYard && userHasRole(this.props.user, this.POWER_YARD_ROLES)) {
			navigation.items.push({
				title: true,
				name: <span style={{ position: 'relative' }}>PowerYard<span style={{
					fontSize: '14px', position: 'absolute',
					top: '-10px',
					right: '-10px'
				}}>&#xAE;</span> </span>,
				wrapper: {
					element: 'div',
					attributes: {
						style: {
							fontFamily: "corbel,sans-serif",
							color: '#30b1a1',
							fontSize: 24,
							fontWeight: 'bold',
							fontStyle: 'italic',
							textTransform: 'none',
							textAlign: 'left',
						}
					}
				}
			});
			{
				this.props.user.company.enableTask &&

					navigation.items.push({
						name: <span title="PowerYard Tasks">Tasks</span>,
						url: '/poweryard-tasks',
						exact: true,
						icon: 'fa fa-truck-moving fa-lg',
						attributes: { disabled: this.props.isDisabled }
					}
					);
			}
		}
		if (this.props.user.company.enableOnsite && this.props.user.company.enablePowerYardPro && userHasRole(this.props.user, [ROLE_E4SCORE_ADMIN, ROLE_POWERYARD_ADMIN, ROLE_POWERYARD_MANAGER, ROLE_POWERYARD_REQUESTER, ROLE_POWERYARD_JOCKEY]) && this.props.currentLocation.enableYardCheck) {
			navigation.items.push({
				name: <span title="On-Site">On-Site</span>,
				url: '/poweryard-onsite',
				exact: true,
				icon: 'fa fa-warehouse',
				attributes: { disabled: this.props.isDisabled }
			});
		}

		if (this.props.user.company.enableGate && this.props.user.company.enablePowerYardPro && userHasRole(this.props.user, [ROLE_GATE_USER])) {
			navigation.items.push({
				name: <span title="Gate">Gate</span>,
				url: '/poweryard-arrival',
				exact: true,
				icon: 'fa fa-warehouse',
				attributes: { disabled: this.props.isDisabled }
			});
		}
		if (this.props.user.company.enablePowerYardPro && this.props.currentLocation.enableArrivalLog && userHasRole(this.props.user, [ROLE_E4SCORE_ADMIN, ROLE_POWERYARD_ADMIN, ROLE_POWERYARD_MANAGER, ROLE_POWERYARD_REQUESTER, ROLE_POWERYARD_JOCKEY])) {
			navigation.items.push({
				name: <span title="Asset Dwell">Dwell History</span>,
				url: '/poweryard-dwell',
				exact: true,
				icon: 'fa fa-truck-clock',
				attributes: { disabled: this.props.isDisabled }
			});
		}
		// if (this.props.user.company.enablePowerYardPro && userHasRole(this.props.user, [ROLE_E4SCORE_ADMIN, ROLE_POWERYARD_ADMIN, ROLE_POWERYARD_MANAGER, ROLE_POWERYARD_JOCKEY, ROLE_POWERYARD_REQUESTER]) && this.props.currentLocation.enableYardCheck) {
		//     navigation.items.push({
		//         name: <span title="Arrival">Arrival</span>,
		//         url: '/poweryard-arrival',
		//         exact: true,
		//         icon: 'fa fa-warehouse'
		//     });
		// }
		// if (this.props.user.company.enablePowerYardPro && userHasRole(this.props.user, [ROLE_E4SCORE_ADMIN, ROLE_POWERYARD_ADMIN, ROLE_POWERYARD_MANAGER, ROLE_POWERYARD_JOCKEY, ROLE_POWERYARD_REQUESTER]) && this.props.currentLocation.enableYardCheck) {
		//     navigation.items.push({
		//         name: <span title="Arrival">Arrival History</span>,
		//         url: '/poweryard-arrival',
		//         exact: true,
		//         icon: 'fa fa-warehouse'
		//     });
		// }
		if (this.props.user.company.enableTask && userHasRole(this.props.user, [ROLE_E4SCORE_ADMIN, ROLE_POWERYARD_ADMIN, ROLE_POWERYARD_MANAGER, ROLE_POWERYARD_REQUESTER])) {
			navigation.items.push({
				name: <span title="Table with history of Tasks">Task History</span>,
				url: '/poweryard-task-history',
				exact: true,
				icon: 'fa fa-tasks fa-lg',
				attributes: { disabled: this.props.isDisabled }
			});
		}
		if (this.props.user.company.enableOnsite && this.props.user.company.enablePowerYardPro && userHasRole(this.props.user, [ROLE_E4SCORE_ADMIN, ROLE_POWERYARD_ADMIN, ROLE_POWERYARD_MANAGER, ROLE_POWERYARD_REQUESTER, ROLE_POWERYARD_JOCKEY]) && this.props.currentLocation.enableYardCheck) {
			navigation.items.push({
				name: <span title="Table with history of Yard">Yard History</span>,
				url: '/poweryard-yardcheck-history',
				exact: true,
				icon: 'fa fa-tasks fa-lg',
				attributes: { disabled: this.props.isDisabled }
			});
		}
		if (this.props.user.company.enableGate && this.props.user.company.enablePowerYardPro && userHasRole(this.props.user, [ROLE_GATE_USER])) {
			navigation.items.push({
				name: <span title="Gate History">Gate History</span>,
				url: '/poweryard-arrival-history',
				exact: true,
				icon: 'fa fa-tasks fa-lg',
				attributes: { disabled: this.props.isDisabled }
			});
		}
		navigation.items.push({
			divider: true,
		});
		return navigation;
	};
	eztracknavigation() {
		let navigation = {
			items: []
		};
		if (this.props.user.company.enableEzTrack && userHasRole(this.props.user, this.EZTRACK_ROLES)) {

			navigation.items.push({
				title: true,
				name: 'EZTrack',
				wrapper: {
					element: 'div',
					attributes: {
						style: {
							fontFamily: "corbel,sans-serif",
							color: '#30b1a1',
							fontSize: 24,
							fontWeight: 'bold',
							fontStyle: 'italic',
							textTransform: 'none',
							textAlign: 'left',
						}
					}
				}
			})
			navigation.items.push({
				name: <span title="Singe Pin for most recent tracking for each asset">Map View</span>,
				url: '/eztrack-map',
				exact: true,
				icon: 'fa fa-map-marked-alt fa-lg'
			});
			navigation.items.push({
				name: <span title="Table for all assets most recent location report">Tracked Assets</span>,
				url: '/eztrack-assets',
				exact: true,
				icon: 'fa fa-th-list fa-lg'
			});
			navigation.items.push({
				name: <span title="Table for asset location history, export">Track History</span>,
				url: '/eztrack-events',
				exact: true,
				icon: 'fa fa-list fa-lg'
			});
		}


		navigation.items.push({
			divider: true,
		});
		return navigation;
	};

	navigation() {
		let navigation = {
			items: []
		};
		if (this.props.user.company.enableEzTrack) {
			navigation = this.eztracknavigation();
		}
		if (this.props.user.company.enablePowerYard) {
			navigation.items = navigation.items.concat(this.powerYardNavigation().items);
		}
		if (this.props.user.company.enableTrackAssured) {
			navigation.items = navigation.items.concat(this.trackAssuredavigation().items);
		}

		if (userHasRole(this.props.user, this.EZCIN_DISPATCH_ROLES.concat(this.EZCIN_WELCOME_CTR_ROLES)) &&
			(this.props.user.company.enableEzCheckInDispatch || this.props.user.company.enableEzCheckInWelcome)) {
			navigation.items.push({
				title: true,
				name: 'EZDispatch',
				wrapper: {
					element: 'div',
					attributes: {
						style: {
							fontFamily: "corbel,sans-serif",
							color: '#30b1a1',
							fontSize: 24,
							fontWeight: 'bold',
							fontStyle: 'italic',
							textTransform: 'none',
							textAlign: 'left',
						}
					}
				}
			})
			if (userHasRole(this.props.user, this.SHIPMENTS_PAGE_ROLES) && this.props.currentLocation && this.props.user.company.shipmentIdDisplay) {
				navigation.items.push({
					name: <span title="Shipment List and access to details, export">Shipments</span>,
					url: '/shipments',
					exact: true,
					icon: 'fa fa-boxes fa-lg'
				});
				navigation.items.push({
					divider: true,
				});
			}
		}

		if (this.props.user.company.enableEzCheckInWelcome) {
			if (userHasRole(this.props.user, this.ARRIVAL_PAGE_ROLES) && this.props.currentLocation && this.props.user.company.shipmentIdDisplay) {
				navigation.items.push({
					name: <span title="Process arriving assets at a facility">Arrival</span>,
					url: '/arrival',
					icon: 'fa fa-truck-moving'
				});
				navigation.items.push({
					name: <span title="Process departing assets from a facility">Departure</span>,
					url: '/departure',
					icon: 'fa fa-shipping-fast fa-flip-horizontal'
				});

			}
			if (userHasRole(this.props.user, this.ASSETS_PAGE_ROLES) && this.props.currentLocation) {
				navigation.items.push({
					name: <span title="Assets that have arrived and not departed a facility">On-Site</span>,
					url: '/assets',
					exact: true,
					icon: 'fa fa-warehouse fa-lg'
				});
			}

			if (userHasRole(this.props.user, this.ASSESSORIALS_PAGE_ROLES) && this.props.currentLocation) {
				navigation.items.push({
					name: <span title="Asset/Location events and details, export">Assessorials</span>,
					url: '/assessorials',
					exact: true,
					icon: 'fa fa-chart-area fa-lg'
				});
				navigation.items.push({
					divider: true,
				});
			}
		}
		if (this.props.user.company.enableEzCheckInDispatch) {
			if (userHasRole(this.props.user, this.DISPATCH_PAGE_ROLES) && this.props.currentLocation && this.props.user.company.shipmentIdDisplay) {
				navigation.items.push({
					name: <span title="Assign assets to a Load, create, edit, view">Dispatch</span>,
					url: "/dispatches",
					icon: 'far fa-shipping-fast'
				});
			}
			if (userHasRole(this.props.user, this.DISPATCH_ASSETS_PAGE_ROLES) && this.props.currentLocation) {
				navigation.items.push({
					name: <span title="Dispatched assets that are on-site">Checked-In</span>,
					url: '/dispatch-assets',
					exact: true,
					icon: 'far fa-warehouse fa-lg'
				});
			}
			if (userHasRole(this.props.user, this.ASSETS_HISTORY_PAGE_ROLES) && this.props.currentLocation) {
				navigation.items.push({
					name: <span title="Asset/Location events and details, export">Event History</span>,
					url: '/assets-history',
					exact: true,
					icon: 'fa fa-tasks fa-lg'
				});
			}
		}

		if (this.props.user.company.enableEzTrack || this.props.user.company.enableEzCheckInDispatch
			|| this.props.user.company.enableEzCheckInWelcome || this.props.user.company.enablePowerYard || this.props.user.company.enableTrackAssured
			|| userHasRole(this.props.user, ROLE_E4SCORE_ADMIN)) {
			navigation.items.push({
				divider: true,
			});
			if (userHasRole(this.props.user, this.ADMINISTRATION_PAGE_ROLES)) {
				navigation.items.push({
					title: true,
					name: 'Administration',
					wrapper: {
						element: 'div',
						attributes: {
							style: {
								fontFamily: "corbel,sans-serif",
								color: '#30b1a1',
								fontSize: 24,
								fontWeight: 'bold',
								fontStyle: 'italic',
								textTransform: 'none',
								textAlign: 'left',
							}
						}
					}
				})
				if (this.props.user.company.enableEzTrack || this.props.user.company.enableEzCheckInDispatch || this.props.user.company.enableTrackAssured
					|| this.props.user.company.enableEzCheckInWelcome || this.props.user.company.enablePowerYard
					|| userHasRole(this.props.user, ROLE_E4SCORE_ADMIN, ROLE_EZTRACK_ADMIN, ROLE_EZTRACK_MANAGER, ROLE_TRACKASSURED_ADMIN, ROLE_POWERYARD_MANAGER)) {
					navigation.items.push({
						name: 'General Settings',
						url: '/admin',
						icon: 'fa fa-cogs',
						attributes: { disabled: this.props.isDisabled }
					});
				}
				if ((this.props.user.company.enableEzCheckInDispatch || this.props.user.company.enableEzCheckInWelcome)
					&& userHasRole(this.props.user, [ROLE_E4SCORE_ADMIN, ROLE_ADMIN, ROLE_CARRIER_ADMIN])) {
					navigation.items.push({
						name: 'EZCheck-In Settings',
						url: '/ezcheck-in-settings',
						icon: 'fa fa-cogs'
					});
				}
				if (this.props.user.company.enablePowerYard && userHasRole(this.props.user, [ROLE_POWERYARD_ADMIN, ROLE_E4SCORE_ADMIN])) {
					navigation.items.push({
						name: 'PowerYard Settings',
						url: '/poweryard-settings',
						icon: 'fa fa-cogs',
						attributes: {
							disabled: this.props.isDisabled
						}
					});
				}
			}
		}


		return navigation;
	}
	SetisDisabled = (value) => {
		this.props.setIsDisable(value);
	};


	renderBody() {
		//this.setState({disabledProgress:localStorage.getItem('DisableScreen')});
		console.log("disablecreen");
		return (
			<div className={"app-body " + (this.state.openCollapse === true ? "animateMargin " : "animateMargin ") + (this.state.tablet === true && this.state.openCollapse === false ? "mt-0" : "")} style={{ overflowX: 'visible' }}>{/*if overflow-x is anything but visible, the dispatch editor's header won't stick correctly */}
				<AppSidebar fixed display={this.state.appSidebarDisplay} id={"app-sidebar"} minimized={this.state.appSidebarMinimized}
					onClick={() => {
						if (this.props.isDisabled) {
							showError(`Must Use "Complete" Button to Exit Screen`);
						}
					}}
				>
					<AppSidebarForm />
					<Suspense>
						<AppSidebarNav navConfig={this.navigation()} {...this.props} />
					</Suspense>
					<AppSidebarFooter />
					<AppSidebarMinimizer className={this.props.isDisabled ? "disableElement" : ""} id={'app-sidebar-minimizer'} />
				</AppSidebar>
				<main className="main column-wrapper">
					<Container fluid className="main-container form-bk column-wrapper">
						<div className='body'>
							<Switch>
								{this.props.user.company.enableEzTrack && userHasRole(this.props.user, this.EZTRACK_ROLES) ?
									<Route path="/eztrack-assets" render={(props) => <EZTrackAssets
										{...props}
										user={this.props.user}
										ezTrackShipmentTrackingEnabled={this.props.user.company.ezTrackShipmentTrackingEnabled}
										distanceUnitsOfMeasure={this.props.user.company.distanceUnitsOfMeasure}
										title={"EZTrack Assets"} get={downloadEZTrackDevicesForTable} />} />
									: ""
								}
								{this.props.user.company.enableEzTrack && userHasRole(this.props.user, this.EZTRACK_ROLES) ?
									<Route path="/eztrack-events" render={(props) => <EZTrackEvents {...props}
										ezTrackShipmentTrackingEnabled={this.props.user.company.ezTrackShipmentTrackingEnabled}
										distanceUnitsOfMeasure={this.props.user.company.distanceUnitsOfMeasure}
										title={"EZTrack Events"} get={downloadEZTrackAssetEvents} />} />
									: ""
								}
								{this.props.user.company.enableEzCheckInDispatch ?
									<Route path="/dispatch-assets" render={(props) => <DispatchAssets {...props}
										title={"Checked-In Assets"} get={downloadDispatchAssetsForTable} />} />
									: ""}
								{this.props.user.company.enableEzCheckInDispatch ?
									<Route path="/assets-history" render={(props) => <AssetsHistoryComponent {...props}
										user={this.props.user} title={"Assessorials"} get={downloadDispatchAssessorialsForTable} />} />
									: ""}
								{this.props.user.company.enableEzCheckInWelcome && userHasRole(this.props.user, this.ARRIVAL_PAGE_ROLES) ?
									<Route path="/arrival" render={(props) => <Arrival company={this.props.user.company} />} />
									: ""
								}
								{this.props.user.company.enableEzCheckInWelcome && userHasRole(this.props.user, this.ARRIVAL_PAGE_ROLES) ?
									<Route path="/departure" render={(props) => <Departure company={this.props.user.company} />} />
									: ""}
								{(this.props.user.company.enableEzTrack || this.props.user.company.enableEzCheckInWelcome || this.props.user.company.enableTrackAssured ||
									this.props.user.company.enableEzCheckInDispatch || this.props.user.company.enablePowerYard
									|| userHasRole(this.props.user, ROLE_E4SCORE_ADMIN))
									&& userHasRole(this.props.user, this.ADMINISTRATION_PAGE_ROLES) ?
									<Route path="/admin" render={(props) =>
										<Admin {...props}
											superAdmin={userHasRole(this.props.user, [ROLE_E4SCORE_ADMIN])}
											defaultCompany={this.props.user.company}
											currentLocation={this.props.currentLocation}
										/>
									} /> :
									""
								}
								{(this.props.user.company.enableEzTrack || this.props.user.company.enableEzCheckInWelcome ||
									this.props.user.company.enableEzCheckInDispatch || this.props.user.company.enablePowerYard
									|| userHasRole(this.props.user, ROLE_E4SCORE_ADMIN))
									&& userHasRole(this.props.user, [ROLE_CARRIER_ADMIN, ROLE_ADMIN, ROLE_E4SCORE_ADMIN]) ?
									<Route path="/ezcheck-in-settings" render={(props) =>
										<EZCheckInSettings {...props}
											superAdmin={userHasRole(this.props.user, [ROLE_E4SCORE_ADMIN])}
											defaultCompany={this.props.user.company}
											currentLocation={this.props.currentLocation}
										/>}
									/> :
									""
								}
								{this.props.user.company.enablePowerYard && userHasRole(this.props.user, [ROLE_POWERYARD_ADMIN, ROLE_E4SCORE_ADMIN]) &&
									<Route path='/poweryard-settings' render={(props) =>
										<PowerYardSettings {...props} user={this.props.user}
											currentLocation={this.props.currentLocation}
											defaultCompany={this.props.user.company}
											superAdmin={userHasRole(this.props.user, [ROLE_E4SCORE_ADMIN])}
										/>} />}
								{this.props.user.company.enableEzCheckInWelcome && userHasRole(this.props.user,
									[ROLE_ADMIN, ROLE_E4SCORE_ADMIN, ROLE_ACCOUNTS_PAYABLE, ROLE_CARRIER_ADMIN, ROLE_CARRIER_ACCOUNTS_PAYABLE, ROLE_CARRIER_DISPATCHER]
								) ? <Route path="/assessorials" render={(props) => <Assets {...props} assessorial={true} />} /> : ""
								}
								{this.props.user.company.enableEzCheckInDispatch && userHasRole(this.props.user, this.DISPATCH_CREATE_ROLES) &&
									<Route path="/new-dispatch" render={(props) => <DispatchEditor dispatch={{ stops: [] }} />} />
								}
								{this.props.user.company.enableEzCheckInDispatch && userHasRole(this.props.user, this.DISPATCH_PAGE_ROLES) &&
									<Route path="/dispatch/:uuid" render={(props) => <DispatchEditor {...props} />} />
								}
								{this.props.user.company.enableEzCheckInDispatch && userHasRole(this.props.user, this.DISPATCH_PAGE_ROLES) &&
									<Route path="/dispatches" render={(props) => <DispatchDispatches {...props} user={this.props.user} showAddNoteActionButton />} />
								}
								{this.props.user.company.enablePowerYard && userHasRole(this.props.user, this.TASKS_PAGE_ROLES) &&
									<Route
										path="/poweryard-tasks"
										render={(props) =>
											this.state.isLocationRequired? (
												<GPSCoordinatedWrapper>
													<Tasks
														setCurrentLocation={this.props.setCurrentLocation}
														setCurrentShift={this.props.setCurrentShift}
														setIsDisable={this.SetisDisabled}
														currentLocation={this.props.currentLocation}
														currentShift={this.props.currentShift}
														user={this.props.user}
														userInTask={this.props.user.inTask}
														disabled={this.props.isDisabled}
														locationRequired={this.state.isLocationRequired}
													/>
												</GPSCoordinatedWrapper>
											) : (
												<Tasks
													setCurrentLocation={this.props.setCurrentLocation}
													setCurrentShift={this.props.setCurrentShift}
													setIsDisable={this.SetisDisabled}
													currentLocation={this.props.currentLocation}
													currentShift={this.props.currentShift}
													user={this.props.user}
													userInTask={this.props.user.inTask}
													disabled={this.props.isDisabled}
												/>
											)
										}
									/>
								}

								{this.props.user.company.enablePowerYard && this.props.user.company.enablePowerYardPro && userHasRole(this.props.user, this.ONSITE_ROLES) &&
									<Route path="/poweryard-onsite" render={(props) =>
										<Onsite
											setCurrentLocation={this.props.setCurrentLocation}
											// allLocations = {this.state.allLocations}
											setCurrentShift={this.props.setCurrentShift}
											setCurrentUser={this.props.setCurrentUser}
											currentLocation={this.props.currentLocation}
											currentShift={this.props.currentShift}
											user={this.props.user}
											userInTask={this.props.user.inTask}
										/>
									} />
								}
								{this.props.user.company.enablePowerYard && this.props.currentLocation.enableArrivalLog && userHasRole(this.props.user, this.DWELL_PAGE_ROLES) &&
									<Route path="/poweryard-dwell" render={(props) =>
										<GPSCoordinatedWrapper>
											<Dwell
												setCurrentLocation={this.props.setCurrentLocation}
												// allLocations = {this.state.allLocations}
												setCurrentShift={this.props.setCurrentShift}
												setIsDisable={this.SetisDisabled}
												currentLocation={this.props.currentLocation}
												currentShift={this.props.currentShift}
												user={this.props.user}
												userInTask={this.props.user.inTask}
												disabled={this.props.isDisabled}

											/>
										</GPSCoordinatedWrapper>
									} />
								}
								{this.props.user.company.enablePowerYard && this.props.user.company.enablePowerYardPro && userHasRole(this.props.user, [ROLE_GATE_USER]) &&
									<Route path="/poweryard-arrival" render={(props) =>
										<GPSCoordinatedWrapper>
											<ArrivalGate
												setCurrentLocation={this.props.setCurrentLocation}
												// allLocations = {this.state.allLocations}
												// setCurrentShift={this.props.setCurrentShift}
												// setCurrentUser={this.props.setCurrentUser}
												currentLocation={this.props.currentLocation}
												// currentShift={this.props.currentShift}
												user={this.props.user}

											/>
										</GPSCoordinatedWrapper>
									} />
								}
								{this.props.user.company.enablePowerYard && this.props.user.company.enablePowerYardPro && userHasRole(this.props.user, [ROLE_GATE_USER]) &&
									<Route path="/poweryard-arrival-history" render={(props) =>
										<GPSCoordinatedWrapper>
											<ArrivalHistory
												setCurrentLocation={this.props.setCurrentLocation}
												// allLocations = {this.state.allLocations}
												// setCurrentShift={this.props.setCurrentShift}
												// setCurrentUser={this.props.setCurrentUser}
												currentLocation={this.props.currentLocation}
												// currentShift={this.props.currentShift}
												user={this.props.user}

											/>
										</GPSCoordinatedWrapper>
									} />
								}
								{this.props.user.company.enablePowerYard && userHasRole(this.props.user, this.TASKS_PAGE_ROLES) &&
									<Route path="/poweryard/asset/:uuid"
										component={(props) => <AssetForm currentLocation={this.props.currentLocation} company={this.props.user.company} user={this.props.user} {...props} />} />
								}
								{this.props.user.company.enablePowerYard && userHasRole(this.props.user, this.TASKS_PAGE_ROLES) &&
									<Route path="/poweryard/assetDetail/:uuid"
										component={(props) => <AssetForm currentLocation={this.props.currentLocation} company={this.props.user.company} user={this.props.user} {...props} />} />
								}
								{this.props.user.company.enablePowerYard && userHasRole(this.props.user, [ROLE_POWERYARD_ADMIN, ROLE_E4SCORE_ADMIN, ROLE_POWERYARD_MANAGER, ROLE_POWERYARD_JOCKEY, ROLE_POWERYARD_REQUESTER]) &&

									// <Route path="/task/:uuid"
									//     component={(props) =>
									//         <GPSCoordinatedWrapper>
									//         <TaskEdit setCurrentLocation={this.props.setCurrentLocation} currentLocation={this.props.currentLocation} company={this.props.user.company} user={this.props.user}  {...props} />
									//         </GPSCoordinatedWrapper>
									//         } />
									<Route
										path="/task/:uuid"
										render={props =>
											this.state.isLocationRequired? (
												<GPSCoordinatedWrapper>
													<TaskEdit
														setCurrentLocation={this.props.setCurrentLocation}
														currentLocation={this.props.currentLocation}
														company={this.props.user.company}
														user={this.props.user}
														{...props}
													/>
												</GPSCoordinatedWrapper>
											) : (
												<TaskEdit
													setCurrentLocation={this.props.setCurrentLocation}
													currentLocation={this.props.currentLocation}
													company={this.props.user.company}
													locationRequired={this.state.isLocationRequired}
													user={this.props.user}
													{...props}
												/>
											)
										}
									/>

								}

								{this.props.user.company.enablePowerYard && this.props.currentLocation.enableTaskInProcess && userHasRole(this.props.user, [ROLE_POWERYARD_ADMIN, ROLE_E4SCORE_ADMIN, ROLE_POWERYARD_MANAGER, ROLE_POWERYARD_JOCKEY, ROLE_POWERYARD_REQUESTER]) && (
									<Route path="/progress/:uuid" render={(props) => (
										<ErrorBoundary >
											<TaskInProgress
												currentLocation={this.props.currentLocation}
												company={this.props.user.company}
												user={this.props.user} {...props}
												setIsDisable={this.SetisDisabled}
												locationRequired={this.state.isLocationRequired}
												disabled={this.props.isDisabled} />
										</ErrorBoundary>
									)} />
								)}

								{this.props.user.company.enablePowerYard && userHasRole(this.props.user, this.TASKS_PAGE_ROLES) &&
									<Route path="/poweryard-task-history" render={(props) =>
										<TaskHistory
											user={this.props.user}
											currentLocation={this.props.currentLocation}
										/>
									} />
								}
								{this.props.user.company.enablePowerYard && this.props.user.company.enablePowerYardPro && userHasRole(this.props.user, this.ONSITE_ROLES) &&
									<Route path="/poweryard-yardcheck-history" render={(props) =>
										<YardCheckHistory
											user={this.props.user}
											currentLocation={this.props.currentLocation}
										/>
									} />
								}
								{/* Change that to TrackAssured checks when available */}
								{this.props.user.company.enableTrackAssured && userHasRole(this.props.user, [ROLE_TRACKASSURED_ADMIN, ROLE_E4SCORE_ADMIN, ROLE_TRACKASSURED_USER]) &&
									<Route path="/shipments-trackassured" render={(props) => <ShipmentsTA {...props} user={this.props.user} stateStorage={"trackassured-shipments"} showAddNoteActionButton />} />
								}
								{this.props.user.company.enableEzTrack && userHasRole(this.props.user, [ROLE_ADMIN, ROLE_E4SCORE_ADMIN, ROLE_OPERATOR, ROLE_ACCOUNTS_PAYABLE, ROLE_ATTENDANT, ROLE_CARRIER_DISPATCHER, ROLE_CARRIER_ACCOUNTS_PAYABLE, ROLE_CARRIER_ADMIN, ROLE_EZTRACK_ADMIN, ROLE_EZTRACK_MANAGER, ROLE_EZTRACK_USER]) &&
									<Route path="/asset-profile/:uuid"
										component={(props) => <EZTrackAssetProfile {...props} />} />
								}
								{(this.props.user.company.enableEzCheckInDispatch || this.props.user.company.enableEzCheckInWelcome) && userHasRole(this.props.user, [ROLE_ADMIN, ROLE_E4SCORE_ADMIN, ROLE_ACCOUNTS_PAYABLE, ROLE_ATTENDANT, ROLE_CARRIER_DISPATCHER, ROLE_CARRIER_ACCOUNTS_PAYABLE, ROLE_CARRIER_ADMIN]) &&
									<Route path="/shipment-profile/:uuid"
										component={(props) => <ShipmentProfile {...props} user={this.props.user} />} />
								}
								{(this.props.user.company.enableTrackAssured) && userHasRole(this.props.user, [ROLE_TRACKASSURED_ADMIN, ROLE_E4SCORE_ADMIN, ROLE_TRACKASSURED_USER]) &&
									<Route path="/trackassured-shipment-profile/:uuid/:duuid"
										component={(props) => <ShipmentTAProfile {...props} user={this.props.user} />} />
								}
								{(this.props.user.company.enableEzCheckInDispatch || this.props.user.company.enableEzCheckInWelcome) && userHasRole(this.props.user, this.SHIPMENTS_PAGE_ROLES) &&
									<Route path="/shipments" render={(props) => <ShipmentDispatches {...props} user={this.props.user} showAddNoteActionButton />} />
								}
								{this.props.user.company.enableEzTrack ?
									<Route path="/eztrack-map" render={(props) => <EZTrackMap {...props} title={"EZTrack Map"} get={downloadPingAssetsForTable} />} />
									: ""
								}
								{this.props.user.company.enableEzCheckInDispatch && userHasRole(this.props.user, [ROLE_E4SCORE_ADMIN, ROLE_CARRIER_ADMIN, ROLE_CARRIER_ACCOUNTS_PAYABLE, ROLE_CARRIER_DISPATCHER]) &&
									<Route path="/" render={(props) => <DispatchDispatches {...props} user={this.props.user} />} />
								}
								{this.props.user.company.enableEzCheckInWelcome && userHasRole(this.props.user, [ROLE_ADMIN, ROLE_E4SCORE_ADMIN, ROLE_ACCOUNTS_PAYABLE, ROLE_ATTENDANT]) &&
									<Route path="/" render={(props) => <Assets {...props} assessorial={false} />} />
								}
								{this.props.user.company.enableEzCheckInWelcome && userHasRole(this.props.user, this.ASSETS_PAGE_ROLES) &&
									<Route path="/assets" render={(props) => <Assets {...props} assessorial={false} />} />
								}
							</Switch>
						</div>
					</Container>
				</main>
				<AppAside fixed>
					<Suspense fallback={this.loading()}>
						<DefaultAside />
					</Suspense>
				</AppAside>
			</div>);
	}

	render() {

		if (this.state.tablet === true) {
			return (
				<div className="app ">
					<Collapse isOpen={this.state.openCollapse === true}>
						<AppHeader fixed id="ez-app-header" className={this.state.vantixCheck === true ? "bg-trackassured" : ""}>
							<Suspense fallback={this.loading()}>
								{this.state.vantixCheck === undefined || this.state.vantixCheck === null || this.state.vantixCheck === false &&
									<DefaultHeader onLogout={e => this.signOut(e)} />
								}
								{this.state.vantixCheck === true &&
									<DefaultHeaderTA onLogout={e => this.signOut(e)} />
								}
							</Suspense>
						</AppHeader>
					</Collapse>
					{this.state.tablet === true &&
						<div className="position-absolute left-50 z-9999">
							<Button className={"customButton " + (this.state.openCollapse === true ? "mt-5" : "mt-2")} onClick={() => {
								this.setState({
									openCollapse: !this.state.openCollapse
								});
							}}><i className={"fas " + (this.state.openCollapse === false ? "fa-chevron-down" : "fa-chevron-up")}></i></Button>

						</div>
					}
					{this.renderBody()}
					<AppFooter id="ez-app-footer">
						<Suspense fallback={this.loading()}>
							{this.state.vantixCheck === undefined || this.state.vantixCheck === null || this.state.vantixCheck === false &&
								<DefaultFooter disable={this.props.isDisabled} />
							}
							{this.state.vantixCheck === true &&
								<DefaultFooterTA disable={this.props.isDisabled} />
							}
						</Suspense>
					</AppFooter>
				</div>);
		} else {
			return (
				<div className="app">
					<AppHeader fixed id="ez-app-header" className={this.state.vantixCheck === true ? "bg-trackassured" : ""}>
						<Suspense fallback={this.loading()}>
							{this.state.vantixCheck === undefined || this.state.vantixCheck === null || this.state.vantixCheck === false &&
								<DefaultHeader onLogout={e => this.signOut(e)} />
							}
							{this.state.vantixCheck === true &&
								<DefaultHeaderTA onLogout={e => this.signOut(e)} />
							}
						</Suspense>
					</AppHeader>
					{this.renderBody()}
					<AppFooter id="ez-app-footer">
						<Suspense fallback={this.loading()}>
							{this.state.vantixCheck === undefined || this.state.vantixCheck === null || this.state.vantixCheck === false &&
								<DefaultFooter disable={this.props.isDisabled} />
							}
							{this.state.vantixCheck === true &&
								<DefaultFooterTA disable={this.props.isDisabled} />
							}
						</Suspense>
					</AppFooter>
				</div>
			);
		}

	}
}

function mapStateToProps(state) {
	return {
		user: state.login.user,
		currentLocation: state.login.location,
		currentShift: state.login.user.shift,
		isDisabled: state.login.isDisabled
	}
}

function mapDispatchToProps(dispatch) {
	return ({
		setCurrentLocation: function (location) {
			dispatch(storeCurrentLocation(location));
		},
		setCurrentUser: function (user) {
			dispatch(storeCurrentUser(user));
		},
		setCurrentShift: function (shift) {
			dispatch(storeCurrentShift(shift));
		},
		setIsDisable: function (isDisabled) {
			dispatch(storeDisableScreen(isDisabled));
		}

	});
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DefaultLayout));