import React, { Component } from "react";
import AsyncSelect from "react-select/lib/Async";
import {
  convertCtoF,
  convertFtoC,
  getErrorsFromValidationError,
} from "../../common/utilities";
import * as Yup from "yup";
import {
  Alert,
  Button,
  Card,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  CardBody,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import FFFormTextField from "../../common/formik-free/FFFormTextField";
import FFFormCheckboxField from "../../common/formik-free/FFFormCheckboxField";
import FFFormSelectCreatableField from "../../common/formik-free/FFFormSelectCreatableField";
import * as PropTypes from "prop-types";
import {
  downloadTaskByUuid,
  getDefaultEZTrackEquipment,
  promiseAssetByPrefix,
  promiseAssetStatusByPrefix,
  carriersByLocationUUid,
  promiseEZTrackEquipmentTypeByPrefix,
  promiseSpotForLocationByZonePrefixOrSpotPrefix,
  promiseTaskTypeByLocationUUid,
  putTaskAssign,
  saveTask,
  downloadAuthorizedLocations,
  downloadEZTrackEquipmentForTable,
  putTaskRemoveAssignee,
  putTaskStart,
  putTaskEnd,
  putTaskCancel,
  promiseCancelReasonSearch,
  getDefaultCancelReasonCode,
  getAllSecondaryReferenceByRefID,
  getTaskReferenceByRefID,
  getSecondaryReferenceByRefID,
  promiseAssetStatusesByLocationUUid,
  getFieldSettingsForScreen,
  promiseEZTrackEquipmentFuel
} from "../../NetworkHelpers";
import FFFormAsyncSelectField from "../../common/formik-free/FFFormAsyncSelectField";
import { showError, showInfo } from "../../MainApp";
import FFFormSelectField from "../../common/formik-free/FFFormSelectField";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import FFFormTextAreaField from "../../common/formik-free/FFFormTextAreaField";
import Loader from "react-loader-advanced";
import LoaderIco from "react-loader";

import CommentTaskModal from "../../common/poweryard/CommentTaskModal";
import FFFormLabelButton from "../../common/formik-free/FFFLabelButton";
import ReferenceModal from "./ReferenceModal";
import HelpIcon from "../../common/HelpIcon";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { fieldnamesProgress } from "../../common/poweryard/FieldNames";

class CancelTaskModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reasonCode: this.props.defaultReasonCode,

    };
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.defaultReasonCode == null &&
      this.props.defaultReasonCode != null
    ) {
      this.setState({ reasonCode: this.props.defaultReasonCode });
    }
  }

  render() {
    return (
      <>
        {this.state.selectedTask !== undefined && (
          <ReferenceModal
            selectedTask={this.state.selectedTask}
            currentLocation={this.props.currentLocation}
            cancel={() => this.setState({ isRefModalOpen: false })}
            isRefModalOpen={this.state.isRefModalOpen}
          />
        )}
        <Modal isOpen={this.props.open} size={"lg"}>
          <ModalHeader>
            <h1>Cancel Task</h1>
          </ModalHeader>
          <ModalBody>
            <Container>
              <Row>
                <Col>
                  {this.state && this.state.error && (
                    <Alert color={"danger"}>{this.state.error}</Alert>
                  )}
                  <AsyncSelect
                    placeholder="Choose a Reason Code"
                    defaultOptions
                    defaultValue={this.props.defaultReasonCode}
                    onChange={(v) => {
                      this.setState({ reasonCode: v }, () => {
                        this.props.onChange && this.props.onChange(v);
                      });
                    }}
                    loadOptions={promiseCancelReasonSearch(true)}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="text-right" style={{ marginTop: "10px" }}>
                  <Button
                    color="danger"
                    onClick={() => this.props.onCancel && this.props.onCancel()}
                  >
                    Don't Cancel
                  </Button>{" "}
                  &nbsp;
                  <Button
                    color="primary"
                    onClick={() => this.onSubmit()}
                    type="submit"
                  >
                    Complete Cancel
                  </Button>
                </Col>
              </Row>
            </Container>
          </ModalBody>
        </Modal>
      </>
    );
  }

  onSubmit() {
    if (!this.state || !this.state.reasonCode) {
      this.setState({ error: "Please select a reason code." });
      return;
    }
    return (
      this.props.onSave &&
      this.props.onSave(this.state && this.state.reasonCode)
    );
  }
}

CancelTaskModal.propTypes = {
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
};

class TemperatureModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setpoint: "",
      actual: "",
      taskUnderEdit: this.props.taskUnderEdit,
      errors: {},
      touched: {},
    };
  }
  getRequiredCheck(field) {
    if (this.props.requiredFields && this.props.requiredFields.length > 0) {
      let index = this.props.requiredFields.findIndex(it => it.fieldName == field);
      if (index > -1) {
        return true;
      } else {
        return false
      }
    } else {
      return false
    }
  }
  validate(getValidationSchema) {
    return (values) => {
      const validationSchema = getValidationSchema(values);
      try {
        validationSchema.validateSync(values, { abortEarly: false });
        return {};
      } catch (error) {
        return getErrorsFromValidationError(error);
      }
    };
  }
  validationSchema(values) {
    if (values.acceptDate && values.acceptDate === "Invalid Date") {
      values.acceptDate = null;
    }
    if (values.start && values.start === "Invalid Date") {
      values.start = null;
    }
    if (values.end && values.end === "Invalid Date") {
      values.end = null;
    }
    const NumberRegex = /^[0-9\b]+$/;
    let validationRules = {
      // type: Yup.string().required("Type is required."),
      asset: Yup.object().nullable(),
      //   moveFrom: Yup.object().nullable(),
      boxedIn: Yup.number()
        .typeError("Number must be between 1 to 9")
        .positive("Number must be between 1 to 9")
        .max(9, ({ max }) => "Number must be between 1 to 9")
        .min(1, ({ min }) => "Number must be between 1 to 9")
        .integer("Number must be between 1 to 9")
        .nullable(),
      assignee: Yup.object().nullable(),
      tempSetPoint: Yup.string()
        .max(
          999.9,
          ({ max }) =>
            "Invalid Entry, maximum of 1 digit after the decimal (999.9)"
        )
        .min(
          -999.9,
          ({ min }) =>
            "Invalid Entry, maximum of 1 digit after the decimal (999.9)"
        )
        // /^-?\d{1,3}(\.\d{1,3})?$/
        .matches(
          /^(?:^-?\d{1,3}(\.\d{1,1})?)?$/,
          "Invalid Entry, maximum of 1 digit after the decimal (999.9)"
        )
        .nullable()
        .transform((_, val) => (val === val ? val : null)),
      actualTemp: Yup.string()
        .max(
          999.9,
          ({ max }) =>
            "Invalid Entry, maximum of 1 digit after the decimal (999.9)"
        )
        .min(
          -999.9,
          ({ min }) =>
            "Invalid Entry, maximum of 1 digit after the decimal (999.9)"
        )
        .matches(
          /^(?:^-?\d{1,3}(\.\d{1,1})?)?$/,
          "Invalid Entry, maximum of 1 digit after the decimal (999.9)"
        )
        .nullable()
        .transform((_, val) => (val === val ? val : null)),

      //   moveTo: Yup.object()
      //     .nullable()
      //     .test("equal", "Move To must not equal Move From", function (v) {
      //       const ref = Yup.ref("moveFrom");
      //       if (v == null) {
      //         return true;
      //       }
      //       if (this.resolve(ref) != null) {
      //         return v.uuid !== this.resolve(ref).uuid;
      //       } else {
      //         return true;
      //       }
      //     }),
      acceptDate: Yup.string().max(
        new Date(),
        ({ max }) =>
          `Task Start Timestamp needs to be prior to the the Task Start Timestamp.`
      ),
      acceptDate: Yup.date()
        .nullable()
        .max(
          new Date(),
          ({ max }) =>
            `Task Start Timestamp needs to be prior to the the Task Start Timestamp.`
        ),
      start: Yup.date()
        .nullable()
        .min(
          Yup.ref("acceptDate"),
          ({ min }) =>
            `Task Start Timestamp needs to be after the assignment time.`
        ),
      end: Yup.date()
        .nullable()
        .min(
          Yup.ref("start"),
          ({ min }) =>
            `Task End Timestamp needs to be after the Task Start Timestamp.`
        ),
    };
    if (this.state.taskUnderEdit.taskStatus == "Complete") {
      if (this.state.requiredFields && this.state.requiredFields.length > 0) {
        for (let index = 0; index < this.state.requiredFields.length; index++) {
          const element = this.state.requiredFields[index];
          let nameOfField = fieldnamesProgress.find(
            (it) => it.value == element.fieldName
          ).label;
          if (validationRules[element.fieldName]) {
            validationRules[element.fieldName] = validationRules[element.fieldName].required(`${nameOfField} is required.`);
          } else {
            console.log("Field not found in validationRules:", element.fieldName);
          }
        }
      }
    }
    return Yup.object().shape(validationRules);
  }
  render() {
    return (
      <>
        <Modal isOpen={this.props.open} size={"lg"}>
          <ModalHeader>Temperature Controlled</ModalHeader>
          <ModalBody className={"bg-white"}>
            <Container>
              <FFFormTextField
                fieldname="tempSetPoint"
                onBlur={() => {
                  let errors = this.validate(this.validationSchema.bind(this))(
                    this.state.taskUnderEdit
                  );
                  let touched = { ...this.state.touched };
                  touched.tempSetPoint = true;
                  this.setState({ errors: errors, touched: touched });
                }}
                error={this.state.errors.tempSetPoint}
                touched={this.state.touched.tempSetPoint}
                label={
                  "Temp - Set Point (" +
                  (this.props.company.temperatureUnitsOfMeasure === "Fahrenheit"
                    ? "℉"
                    : "℃") +
                  ")"
                }
                placeholder="Enter the Set Point temperature"
                icon="fa-temperature-high"
                defaultValue={this.props.taskUnderEdit.tempSetPoint}
                numbersOnly={false}
                required={(this.props.taskUnderEdit.taskStatus === "Complete" && this.getRequiredCheck("tempSetPoint")) ? true : false}
                onChange={(v) => {
                  let s = { ...this.state.taskUnderEdit };
                  s.tempSetPoint = v;
                  this.setState({ taskUnderEdit: s });
                }}
              />

              <FFFormTextField
                fieldname="actualTemp"
                label={
                  "Temp - Actual (" +
                  (this.props.company.temperatureUnitsOfMeasure === "Fahrenheit"
                    ? "℉"
                    : "℃") +
                  ")"
                }
                onBlur={() => {
                  let errors = this.validate(this.validationSchema.bind(this))(
                    this.state.taskUnderEdit
                  );
                  let touched = { ...this.state.touched };
                  touched.actualTemp = true;
                  this.setState({ errors: errors, touched: touched });
                }}
                error={this.state.errors.actualTemp}
                touched={this.state.touched.actualTemp}
                placeholder="Enter the actual temperature"
                icon="fa-temperature-high"
                defaultValue={this.props.taskUnderEdit.actualTemp}
                numbersOnly={false}
                required={(this.props.taskUnderEdit.taskStatus === "Complete" && this.getRequiredCheck("actualTemp")) ? true : false}
                valid={true}
                invalid={false}
                onChange={(v) => {
                  let s = { ...this.state.taskUnderEdit };
                  s.actualTemp = v;
                  this.setState({ taskUnderEdit: s });
                }}
              />

              <Row>
                <Col className="text-right" style={{ marginTop: "10px" }}>
                  <Button
                    color="danger"
                    onClick={() => {
                      this.state.taskUnderEdit.tempSetPoint = undefined;
                      this.state.taskUnderEdit.actualTemp = undefined;

                      this.setState({ touched: [], errors: [] });
                      this.props.onCancel && this.props.onCancel();
                    }}
                  >
                    Cancel
                  </Button>{" "}
                  &nbsp;
                  <Button
                    color="primary"
                    onClick={() => this.onSubmit()}
                    type="submit"
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Container>
          </ModalBody>
        </Modal>
      </>
    );
  }

  onSubmit() {
    let errors = this.validate(this.validationSchema.bind(this))(
      this.state.taskUnderEdit
    );

    let errorsIsEmpty =
      Object.keys(errors).length === 0 && errors.constructor === Object;
    if (!errorsIsEmpty || this.state.errors.name) {
      let t = { ...this.state.touched };
      t.tempSetPoint = true;
      t.actualTemp = true;
      this.setState({ touched: t, errors: errors });
      return;
    }
    if (this.state.taskUnderEdit.actualTemp === undefined) {
      let s = this.props.taskUnderEdit;
      this.state.taskUnderEdit.actualTemp = s.actualTemp;
    }
    if (this.state.taskUnderEdit.tempSetPoint === undefined) {
      let s = this.props.taskUnderEdit;
      this.state.taskUnderEdit.tempSetPoint = s.tempSetPoint;
    }
    return (
      this.props.onSave &&
      this.props.onSave(this.state && this.state.taskUnderEdit)
    );
  }
}

TemperatureModal.propTypes = {
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

export const getTemp = function (row, setPoint, user) {
  let temperatureInC = row.original.temperatureInC;

  if (temperatureInC == null) {
    return "";
  }

  if (setPoint != null) {
    if (isF(user)) {
      let tempInF = convertCtoF(temperatureInC);
      let setPointInF = convertCtoF(setPoint);
      tempInF -= setPointInF;
      return tempInF.toFixed(1) + " ℉";
    } else {
      temperatureInC -= setPoint;
      return temperatureInC.toFixed(1) + " ℃";
    }
  }
  if (isF(user)) {
    return convertCtoF(temperatureInC).toFixed(1) + " ℉";
  } else {
    return temperatureInC.toFixed(1) + " ℃";
  }
};
export const isF = function (user) {
  return (
    user &&
    user.company &&
    user.company.temperatureUnitsOfMeasure &&
    user.company.temperatureUnitsOfMeasure === "Fahrenheit"
  );
};

class TaskInProgress extends Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    // this.handleClick = this.handleClick.bind(this);
    this.tableRef = React.createRef();
    this.state = {
      isLocationRequired:this.props.user.roles[0].role === "ROLE_POWERYARD_JOCKEY"?true:false,
      saveclick: false,
      secRefNumber: null,
      topggleCheck: false,
      taskUnderEdit: {
        name: "",
        location: this.props.currentLocation,
        enabled: true,
        isNew: true,
      },
      errors: {},
      touched: {},
      loaded: false,
      showSpotHistory: true,
      defaultEquipmentType: {},
      allLocations: [],
      allEquipmentTypes: [],
      closeCheck: false,
      watcher: undefined,
      taskProgressTypeChecker: "",
      selectedTask: {
        refData: {
          prNumber: null,
          prName: null,
          prAppointmentDate: null,
          prAppointmentWindow: null,
          prAppointmentType: null,
          prAppointmentPlan: null,
          prScac: null,
          prRefIdFlagDate: null,
          cancelled: null,
          prRefIdFlag: null,
          uuid: null,
          carrier: {},
          location: null,
          secondaryRef: "",
        },
      },
    };

  }
  // handleClick(event) {
  //   if (this.props.disabled==true && !this.ref.current.contains(event.target)) {
  //     // Show an error
  //     showError('Must Use "Complete" Button to Exit Screen');
  //   }
  // }
  getRequiredCheck(field) {
    if (this.state.requiredFields && this.state.requiredFields.length > 0) {
      let index = this.state.requiredFields.findIndex(it => it.fieldName == field);
      if (index > -1) {
        return true;
      } else {
        return false
      }
    } else {
      return false
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({
      loaded: true,
    });
    this.updateSpinner("Fetching Data");
    // document.addEventListener("click", this.handleClick);

    getDefaultCancelReasonCode(
      (success) => {
        let data = success.content;
        data.label = data.name;
        data.value = data.uuid;
        this.setState({
          defaultCancelReasonCode: data,
        });
      },
      () => {
        this.setState({
          defaultCancelReasonCode: null,
        });
      }
    );
    getDefaultEZTrackEquipment(this.props.currentLocation.uuid, (def) => {
      this.setState({
        defaultEquipmentType: def.content,
      });
    });
    if (this.props.match.params.uuid !== "new") {
      this.loadTask(this.props.match.params.uuid);
    }
    let searchRequest = { page: 0, pageSize: 50, sorted: [], filtered: [] };
    downloadEZTrackEquipmentForTable(searchRequest, (response) => {
      this.setState({ allEquipmentTypes: response.content });
    });
    downloadAuthorizedLocations((response) => {
      this.setState({ allLocations: response.content });
    });

    promiseTaskTypeByLocationUUid(this.props.currentLocation.uuid)("").then(
      (results) => {
        let task = { ...this.state.taskUnderEdit };
        if (!task.type) {
          task.type = results.filter((t) => t.def)[0];
        }
        if (this.props.user.roles[0].role === "ROLE_POWERYARD_JOCKEY") {
          task.assignee = this.props.user;
        }
        this.setState({ taskTypeOptions: results, taskUnderEdit: task });
      }
    );
    let settingObj = {
      screenCode: "task-in-progress",
      locationUuid: this.props.currentLocation.uuid,
    };
    getFieldSettingsForScreen(settingObj, (res) => {
      let mfields = res.content.mandatoryFields;

      this.setState({ requiredFields: mfields });
    });
    // promiseAssetStatusesByLocationUUid(this.props.currentLocation.uuid, (res) => {
    //   let listData = res.content.map((d) => {
    //     return {
    //       value: d.name,
    //       label: d.name,
    //       ...d
    //     }
    //   });
    //   let task = { ...this.state.taskUnderEdit };
    //   if (!task.assetStatus) {
    //     task.assetStatus = listData.filter((t) => t.default)[0];

    //   }

    //   this.setState({ assetStatusOptions: listData, taskUnderEdit: task });
    // });
    // pro=

  }
  componentDidUpdate() {
    console.log("required array", this.state.requiredFields, this.state.errors);
    if (!this.state.saveclick && this.state.taskUnderEdit.taskStatus == "Complete") {
      localStorage.setItem('DisableScreen', true);
      this.props.setIsDisable(true);
    }
    else if (this.state.saveclick == true) {
      this.props.setIsDisable(false);

    }

  }
  componentWillUnmount() {
    if (this.state.taskResponse = "1000") {
      this.props.setIsDisable(false);
    }
  }
  getFlooredFixed(v, d) {
    return (Math.floor(v * Math.pow(10, d)) / Math.pow(10, d)).toFixed(d);
  }
  updateSpinner(val) {
    this.spinner = (
      <span>
        <p className="loadingMessage">{val}</p>
        <LoaderIco
          loaded={false}
          lines={13}
          length={20}
          width={10}
          radius={30}
          corners={1}
          rotate={0}
          direction={1}
          color="#51b32e"
          speed={1}
          trail={60}
          shadow={false}
          hwaccel={false}
          className="spinner"
          zIndex={2e9}
          top="50%"
          left="50%"
          scale={1.0}
          loadedClassName="loadedContent"
        />
      </span>
    );
  }
  loadTask(uuid) {
    downloadTaskByUuid(
      uuid,
      (data) => {
        let success = data.content;
        // adding assetId to handle required check in case of mandatoray field
        if (success.asset != null && success.asset != undefined) {
          success.assetId = success.asset;
          success.assetType = success.asset.equipment
        }

        {

        }
        if (
          this.props.company.temperatureUnitsOfMeasure === "Fahrenheit" ||
          this.props.company.temperatureUnitsOfMeasure !== "Celsius"
        ) {
          success.tempSetPoint = convertCtoF(success.temperatureSetPointInC);
          if (success.tempSetPoint !== null) {
            success.tempSetPoint = this.getFlooredFixed(
              success.tempSetPoint,
              1
            );
          }
          success.actualTemp = convertCtoF(success.temperatureInC);
          if (success.actualTemp !== null) {
            success.actualTemp = this.getFlooredFixed(success.actualTemp, 1);
          }
        }
        if (this.props.company.temperatureUnitsOfMeasure === "Celsius") {
          success.tempSetPoint = success.temperatureSetPointInC;
          if (success.temperatureSetPointInC !== null) {
            success.tempSetPoint = this.getFlooredFixed(
              success.tempSetPoint,
              1
            );
          }
          success.actualTemp = success.temperatureInC;
          if (success.actualTemp !== null) {
            success.actualTemp = this.getFlooredFixed(success.actualTemp, 1);
          }
        }

        if (success.fuelLevel) {

          success.fuelLevel.label = success.fuelLevel.fuelLevel;

        }
        //   if (this.props.company.temperatureUnitsOfMeasure === "Fahrenheit" || this.props.company.temperatureUnitsOfMeasure !== "Celsius") {
        //     success.tempSetPoint = convertCtoF(success.temperatureSetPointInC);
        //     success.actualTemp = convertCtoF(success.temperatureInC);
        // }
        // if (this.props.company.temperatureUnitsOfMeasure === "Celsius") {
        //     success.tempSetPoint = success.temperatureSetPointInC;
        //     success.actualTemp = success.temperatureInC;
        // }
        if (success.acceptDate && success.acceptDate != null) {
          success.acceptDate = this.convertDateTimeToLocationTZ(
            success.acceptDate,
            success.location.timezone
          );
        } else {
          success.acceptDate = null;
        }
        if (success.startDate && success.startDate != null) {
          success.start = this.convertDateTimeToLocationTZ(
            success.startDate,
            success.location.timezone
          );
        } else {
          success.start = null;
        }
        if (success.endDate && success.endDate != null) {
          success.end = this.convertDateTimeToLocationTZ(
            success.endDate,
            success.location.timezone
          );
        } else {
          success.end = null;
        }

        success.secondaryRef =
          success.secondaryRef === "" ? null : success.secondaryRef;
        if (success.secondaryRef !== null) {
          var singleRefObj = success.secondaryRef.split(",")[0];
          var seRefObj = {
            value: singleRefObj,
            label: singleRefObj,
            uuid: singleRefObj,
            valueSubmit: success.secondaryRef,
          };
          success.secondaryRef = seRefObj;
        }
        this.setState({
          taskUnderEdit: success,
          loaded: false,
          showSealNumber: data.content.sealed,
          showSealNumber: data.content.sealed,
          secRefNumber: success.secondaryRef,
        });
      },
      (failure) => {
        showError("Unable to access task.");
      }
    );
  }
  cancelEdit() {
    // this.setState({ loaded: true });
    confirmAlert({
      title: "Confirm Cancel",
      message: `Are you sure to cancel changes.`,
      buttons: [
        {
          label: "No",
          className: "btn danger",
          onClick: () => {
            return;
          },
        },
        {
          label: "Yes",
          className: "btn btn-primary",
          onClick: () => {
            this.updateSpinner("Unassigning ...");
            this.setState({ secRefNumber: null });
            this.props.history.push("/poweryard-tasks");
          },
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
      overlayClassName: "overlay-custom-class-name",
    });
  }
  convertDateTimeToLocationTZ(tdate, timezone) {
    if (tdate !== undefined && tdate !== null) {
      const datetime = moment.utc(tdate).clone().tz(timezone);
      const date = datetime.date();
      const month = datetime.month();
      const year = datetime.year();
      const hours = datetime.hours();
      const minutes = datetime.minutes();
      const seconds = datetime.seconds();
      const millisecond = datetime.millisecond();

      const localDate = moment();
      localDate.set({
        year: year,
        month: month,
        date: date,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
        millisecond: millisecond,
      });
      return localDate.toDate();
    } else {
      return null;
    }
  }
  onSubmit(val, taskObj) {
    let task = {};
    if (taskObj) {
      if (taskObj.type === "temperature") {
        task = { ...this.state.taskUnderEdit };
        task.tempSetPoint = taskObj.tempSetPoint;
        task.actualTemp = taskObj.actualTemp;
      } else {
        task = { ...this.state.taskUnderEdit };
        task.comments = taskObj.comments;
      }
    } else {
      task = { ...this.state.taskUnderEdit };
    }
    let taskToBeSubmit = {};
    // taskToBeSubmit.boxedIn = task.boxedIn === 0 ? null : task.boxedIn;
    taskToBeSubmit.boxedIn = task.boxedIn;
    taskToBeSubmit.type = {
      uuid: task.type.uuid,
      name: task.type.name,
    };
    taskToBeSubmit.location = {
      uuid: task.location.uuid,
      name: task.location.name,
    };
    if (task.isNew === true) {
      taskToBeSubmit.isNew = true;
    }
    if (task.carrier) {
      taskToBeSubmit.carrier = {
        uuid: task.carrier.uuid,
        name: task.carrier.name,
      };
    }
    if (task.assignee) {
      taskToBeSubmit.assignee = {
        uuid: task.assignee.uuid,
        username: task.assignee.username,
      };
    }
    if (task.assetStatus) {
      taskToBeSubmit.assetStatus = {
        uuid: task.assetStatus.uuid,
        name: task.assetStatus.name,
      };
    }

    if (task.fuelLevel) {
      if ((task.asset &&
        task.asset.equipment &&
        task.asset.equipment.reefer) ||
        (task.assetType &&
          task.assetType.reefer)) {

        if (!taskToBeSubmit.fuelLevel) {
          taskToBeSubmit.fuelLevel = {}; // Initialize fuelLevel if it doesn't exist
        }

        taskToBeSubmit.fuelLevel.uuid = task.fuelLevel.uuid;
        taskToBeSubmit.fuelLevel.name = task.fuelLevel.name;
        taskToBeSubmit.fuelLevel.fuelLevel = task.fuelLevel.fuelLevel;
        
        
      }
      else {

        taskToBeSubmit.fuelLevel = null;


      }

    }
    else {
      taskToBeSubmit.fuelLevel = null;
    }
    if (task.reefer) {
      if ((task.asset &&
        task.asset.equipment &&
        task.asset.equipment.reefer) ||
        (task.assetType &&
          task.assetType.reefer)) {

        taskToBeSubmit.reefer = task.reefer;
      
      }
      else {
        taskToBeSubmit.reefer = null;
      }
    }

    else {
      if ((task.asset &&
        task.asset.equipment &&
        task.asset.equipment.reefer) ||
        (task.assetType &&
          task.assetType.reefer) ) {
        taskToBeSubmit.reefer = false;

      }
      else {
        taskToBeSubmit.reefer = null;
      }
    }
    if (task.continous) {
      if ((task.asset &&
        task.asset.equipment &&
        task.asset.equipment.reefer) ||
        (task.assetType &&
          task.assetType.reefer)) {

        taskToBeSubmit.continous = task.continous;
     
      }
      else {
        taskToBeSubmit.continous = null;
      }
    }
    else {

      if ((task.asset &&
        task.asset.equipment &&
        task.asset.equipment.reefer) ||
        (task.assetType &&
          task.assetType.reefer) ||
        (this.state.defaultEquipmentType &&
          this.state.defaultEquipmentType.reefer == true &&
          this.state.newAsset === true)) {
        taskToBeSubmit.continous = false;

      }
      else {
        taskToBeSubmit.continous = null;
      }
    }
    if (task.assetType) {
      // taskToBeSubmit.assetType = {
      //   uuid : task.assetType.uuid,
      //   name : task.assetType.name,
      //   baseEquipment: task.assetType.baseEquipment
      // };
      taskToBeSubmit.assetType = task.assetType.name;
    }
    else {
      if (task.asset != null && task.asset.equipment !== null &&
        task.asset.equipment.baseEquipment !== null &&
        task.asset.equipment.baseEquipment !== undefined
      ) {
        taskToBeSubmit.assetType = task.asset.equipment.name
      }
    }


    if (task.moveTo) {
      taskToBeSubmit.moveTo = {
        uuid: task.moveTo.uuid,
        name: task.moveTo.zoneName,
      };
    }
    if (task.moveFrom) {
      taskToBeSubmit.moveFrom = {
        uuid: task.moveFrom.uuid,
        name: task.moveFrom.zoneName,
      };
    }
    if (task.asset != null) {
      if (task.asset.equipment === undefined || task.asset.equipment === "") {
        task.asset.equipment = null;
        // task.asset["@type"] = this.state.defaultEquipmentType.equipmentType;
        task.asset.equipment = this.state.defaultEquipmentType;
      } else if (
        task.asset.equipment !== null &&
        task.asset.equipment.baseEquipment !== null &&
        task.asset.equipment.baseEquipment !== undefined
      ) {
        // task.asset["@type"] = task.asset.equipment.baseEquipment.equipmentType;
      }
    }

    if (task.asset) {
      taskToBeSubmit.asset = {
        label: task.asset.label,
        value: task.asset.value,
        __isNew__: task.asset.__isNew__,
        assetId: task.asset.assetId,
        uuid: task.asset.uuid,
        equipment: {
          uuid: task.asset.equipment.uuid,
          name: task.asset.equipment.name,
          temperatureControlled: task.asset.equipment.temperatureControlled,
          baseEquipment: {
            uuid: task.asset.equipment.baseEquipment.uuid,
            equipmentType: task.asset.equipment.baseEquipment.equipmentType,
          },
        },
      };
    } else {
      if (task.assetType) {
        task.asset = {
          "@type": task.assetType.baseEquipment.equipmentType,
          // domicile: this.props.currentLocation,

          equipment: task.assetType,
        };
        taskToBeSubmit.asset = task.asset;
      }
    }
    if(this.props.locationRequired){
    
    if (navigator.geolocation) {
      let positionOptions = {
        enableHighAccuracy: true,
        timeout: 10000,
        maximumAge: 10000,
      };
      navigator.geolocation.getCurrentPosition(
        (position) => {
          taskToBeSubmit.latitude = position.coords.latitude;
          taskToBeSubmit.longitude = position.coords.longitude;
        },
        (error) => {
          this.setState({ loaded: false });
          switch (error.code) {
            case error.PERMISSION_DENIED:
              showError(
                "User denied the request for Geolocation.Please allow."
              );
              break;
            case error.POSITION_UNAVAILABLE:
              showError(
                "Location information is unavailable.Please try again!"
              );
              break;
            case error.TIMEOUT:
              showError(
                "The request to get user location timed out.Please refresh!"
              );
              break;
            case error.UNKNOWN_ERROR:
              showError("An unknown error occurred.");
              break;
          }
        },
        positionOptions
      );
    }

  }
    if (task.actualTemp === "") {
      task.actualTemp = null;
    }
    if (task.tempSetPoint === "") {
      task.tempSetPoint = null;
    }
    if (
      this.props.company.temperatureUnitsOfMeasure === "Fahrenheit" ||
      this.props.company.temperatureUnitsOfMeasure !== "Celsius"
    ) {
      task.temperatureSetPointInC = convertFtoC(task.tempSetPoint);
      task.temperatureInC = convertFtoC(task.actualTemp);
      this.setState({ taskUnderEdit: task });
    }
    if (this.props.company.temperatureUnitsOfMeasure === "Celsius") {
      task.temperatureSetPointInC = task.tempSetPoint;
      task.temperatureInC = task.actualTemp;
      this.setState({ taskUnderEdit: task });
    }
    if (task.asset) {
      taskToBeSubmit.assetType = task.asset.equipment.name;
    }

    if (task.tempSetPoint) {
      if ((task.asset &&
        task.asset.equipment &&
        task.asset.equipment.temperatureControlled) ||
        (task.assetType &&
          task.assetType.temperatureControlled)) {

        taskToBeSubmit.tempSetPoint = task.tempSetPoint;
      }
      else {

        taskToBeSubmit.tempSetPoint = null;


      }
      // taskToBeSubmit.continous = task.continous;
    }
    if (task.actualTemp) {
      if ((task.asset &&
        task.asset.equipment &&
        task.asset.equipment.temperatureControlled) ||
        (task.assetType &&
          task.assetType.temperatureControlled)) {

        taskToBeSubmit.actualTemp = task.actualTemp;
      }
      else {

        taskToBeSubmit.actualTemp = null;


      }
      // taskToBeSubmit.continous = task.continous;
    }
    if (task.temperatureSetPointInC) {
      if ((task.asset &&
        task.asset.equipment &&
        task.asset.equipment.temperatureControlled) ||
        (task.assetType &&
          task.assetType.temperatureControlled)) {

        taskToBeSubmit.temperatureSetPointInC = task.temperatureSetPointInC;
      }
      else {

        taskToBeSubmit.temperatureSetPointInC = null;


      }
      // taskToBeSubmit.continous = task.continous;
    }
    if (task.temperatureInC) {
      if ((task.asset &&
        task.asset.equipment &&
        task.asset.equipment.temperatureControlled) ||
        (task.assetType &&
          task.assetType.temperatureControlled)) {

        taskToBeSubmit.temperatureInC = task.temperatureInC;
      }
      else {

        taskToBeSubmit.temperatureInC = null;


      }
      // taskToBeSubmit.continous = task.continous;
    }
    taskToBeSubmit.priority = task.priority;
    taskToBeSubmit.comments = task.comments;
    taskToBeSubmit.shipmentNumber = task.shipmentNumber;
    taskToBeSubmit.sealNumber = task.sealNumber;
    taskToBeSubmit.sealed = task.sealed === true ? task.sealed : false;
    taskToBeSubmit.enabled = task.enabled;
    taskToBeSubmit.completedManually = task.completedManually;
    taskToBeSubmit.shift = task.shift;
    taskToBeSubmit.company = this.props.company;
    taskToBeSubmit.uuid = task.uuid;
    taskToBeSubmit.assignDate = task.assignDate;
    taskToBeSubmit.acceptDate = this.convertDateTime(
      task.acceptDate,
      this.state.taskUnderEdit.location.timezone
    );
    taskToBeSubmit.startDate = this.convertDateTime(
      task.start,
      this.state.taskUnderEdit.location.timezone
    );
    taskToBeSubmit.endDate = this.convertDateTime(
      task.end,
      this.state.taskUnderEdit.location.timezone
    );
    var secondaryRef = null;
    if (
      this.state.taskUnderEdit.secondaryRef !== null &&
      this.state.taskUnderEdit.secondaryRef !== "" &&
      this.state.taskUnderEdit.secondaryRef !== undefined
    ) {
      secondaryRef = this.state.taskUnderEdit.secondaryRef.valueSubmit;
      taskToBeSubmit.secondaryRef = secondaryRef;
    } else {
      taskToBeSubmit.secondaryRef = secondaryRef;
    }

    // add updated
    taskToBeSubmit.taskVersion = task.taskVersion;
   
    this.updateSpinner("Saving ...");
    this.setState({ loaded: true });
    this.submittingTask(taskToBeSubmit, val);
    // if (this.state.taskUnderEdit.taskStatus == "Complete") {
    //   if (this.state.requiredFields && this.state.requiredFields.length > 0) {
    //     for (let index = 0; index < this.state.requiredFields.length; index++) {
    //       const element = this.state.requiredFields[index];
    //       let nameOfField = fieldnamesProgress.find(
    //         (it) => it.value == element.fieldName
    //       ).label;

    //       if (
    //         !nameOfField.includes("Temperature") && taskToBeSubmit.asset && taskToBeSubmit.asset.equipment && taskToBeSubmit.asset.equipment.temperatureControlled === false
    //       ) 
    //       {
    //         console.log("in on submit",nameOfField);
    //         if (
    //           element.fieldName !== "assetId" &&
    //           (taskToBeSubmit[element.fieldName] == null ||
    //             taskToBeSubmit[element.fieldName] === "" ||
    //             taskToBeSubmit[element.fieldName] === undefined)
    //         ) {
    //           this.setState({ loaded: false });
    //           showError(`${nameOfField} is required`);
    //           return;
    //         }
    //       }
    //       else if(nameOfField.includes("Temperature")&& taskToBeSubmit.asset && taskToBeSubmit.asset.equipment && taskToBeSubmit.asset.equipment.temperatureControlled === true)
    //       {if (
    //         element.fieldName !== "assetId" &&
    //         (taskToBeSubmit[element.fieldName] == null ||
    //           taskToBeSubmit[element.fieldName] === "" ||
    //           taskToBeSubmit[element.fieldName] === undefined)
    //       ) {
    //         this.setState({ loaded: false });
    //         showError(`${nameOfField} is required`);
    //         return;
    //       }}

    //       if (
    //         element.fieldName === "assetId" &&
    //         (!taskToBeSubmit.asset ||
    //           taskToBeSubmit.asset.assetId == null ||
    //           taskToBeSubmit.asset.assetId === "" ||
    //           taskToBeSubmit.asset.assetId === undefined)
    //       ) {
    //         this.setState({ loaded: false });
    //         showError(`${nameOfField} is required`);
    //         return;
    //       }
    //     }
    //   }

    //   this.submittingTask(taskToBeSubmit, val);
    // } else {
    //   this.submittingTask(taskToBeSubmit, val);
    // }
  }
  submittingTask(taskToBeSubmit, val) {

    saveTask(
      taskToBeSubmit,
      (response) => {
        this.setState({ touched: {} });
        if (response.code === "1000") {
          this.setState({ loaded: false, taskResponse: "1000" });
          showError(response.message);
          this.props.history.push("/poweryard-tasks");
        }
        if (val === "temp") {
          showInfo("Task saved.");
          this.loadTask(response.content.uuid);
          this.setState({ temperatureModalOpen: false, loaded: false });
        } else if (val === "comment") {
          showInfo("Task saved.");
          this.loadTask(response.content.uuid);
          this.setState({ commentTaskModalOpen: false, loaded: false });
        } else if (val === true) {
          this.props.history.push("/poweryard-tasks");
        } else {
          if (this.state.taskProgressTypeChecker === "") {
            this.loadTask(response.content.uuid);
            showInfo("Task saved.");
            this.setState({ loaded: false });
          } else {
            this.getLocationFireEvent(
              "",
              response.content,
              this.state.taskProgressTypeChecker,
              this.props.company
            );
          }
        }

        this.setState({ closeCheck: false, taskProgressTypeChecker: "" });
      },
      () => {
        showError("Unable to save task.");
      }
    );
  }
  convertDateTime(tdate, timezone) {
    if (tdate !== undefined && tdate !== null) {
      const datetime = moment(tdate);
      const date = datetime.date();
      const month = datetime.month();
      const year = datetime.year();
      const hours = datetime.hours();
      const minutes = datetime.minutes();
      const seconds = datetime.seconds();
      const millisecond = datetime.millisecond();

      const localDate = moment(datetime);

      // Convert the date to the "America/Chicago" timezone
      const convertedDate = localDate.clone().tz(timezone);

      convertedDate.set({
        year: year,
        month: month,
        date: date,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
        millisecond: millisecond,
      });
      return convertedDate.toISOString();
    } else {
      return null;
    }
  }
  validate(getValidationSchema) {
    return (values) => {
      const validationSchema = getValidationSchema(values);
      try {
        validationSchema.validateSync(values, { abortEarly: false });
        return {};
      } catch (error) {
        return getErrorsFromValidationError(error);
      }
    };
  }
  validationSchema(values) {
    if (values.acceptDate && values.acceptDate === "Invalid Date") {
      values.acceptDate = null;
    }
    if (values.start && values.start === "Invalid Date") {
      values.start = null;
    }
    if (values.end && values.end === "Invalid Date") {
      values.end = null;
    }
    const NumberRegex = /^[0-9\b]+$/;
    let validationRules = {
      type: Yup.string().required("Type is required."),
      shipmentNumber: Yup.string().nullable(),
      moveFrom: Yup.object().nullable(),
      fuelLevel: Yup.object().nullable(),
      moveTo: Yup.object().nullable(),
      reefer: Yup.boolean().nullable(),
      continous: Yup.boolean().nullable(),
      sealed: Yup.boolean().nullable(),
      sealNumber: Yup.string().nullable(),
      comments: Yup.string().nullable(),
      carrier: Yup.object().nullable(),
      assetType: Yup.object().nullable(),
      assetStatus: Yup.object().nullable(),
      assetId: Yup.object().nullable(),
      secondaryRef: Yup.string().nullable(),
      // asset: Yup.object().shape({
      //   assetId: Yup.object().nullable(),
      // }),
      boxedIn: Yup.number()
        .typeError("Number must be between 1 to 9")
        .positive("Number must be between 1 to 9")
        .max(9, "Number must be between 1 to 9")
        .min(1, "Number must be between 1 to 9")
        .nullable(),

      assignee: Yup.object().nullable(),
      // 
      tempSetPoint: Yup.string()
        .max(
          999.9,
          ({ max }) =>
            "Invalid Entry, maximum of 1 digit after the decimal (999.9)"
        )
        .min(
          -999.9,
          ({ min }) =>
            "Invalid Entry, maximum of 1 digit after the decimal (999.9)"
        )
        // /^-?\d{1,3}(\.\d{1,3})?$/
        .matches(
          /^(?:^-?\d{1,3}(\.\d{1,1})?)?$/,
          "Invalid Entry, maximum of 1 digit after the decimal (999.9)"
        )
        .nullable()
        .transform((_, val) => (val === val ? val : null)),
      actualTemp: Yup.string()
        .max(
          999.9,
          ({ max }) =>
            "Invalid Entry, maximum of 1 digit after the decimal (999.9)"
        )
        .min(
          -999.9,
          ({ min }) =>
            "Invalid Entry, maximum of 1 digit after the decimal (999.9)"
        )
        .matches(
          /^(?:^-?\d{1,3}(\.\d{1,1})?)?$/,
          "Invalid Entry, maximum of 1 digit after the decimal (999.9)"
        )
        .nullable()
        .transform((_, val) => (val === val ? val : null)),

      acceptDate: Yup.date().nullable(),

      start: Yup.date()
        .nullable()
        .min(
          Yup.ref("acceptDate"),
          ({ min }) =>
            `Task Start Timestamp needs to be after the assignment time.`
        ),
      end: Yup.date()
        .nullable()
        .min(
          Yup.ref("start"),
          ({ min }) =>
            `Task End Timestamp needs to be after the Task Start Timestamp.`
        ),
    };
    if (this.state.taskUnderEdit.taskStatus == "Complete") {
      if (this.state.requiredFields && this.state.requiredFields.length > 0) {
        for (let index = 0; index < this.state.requiredFields.length; index++) {
          const element = this.state.requiredFields[index];
          let nameOfField = fieldnamesProgress.find(
            (it) => it.value == element.fieldName
          ).label;
          if (nameOfField.toLocaleLowerCase().includes("temperature")) {
            if (this.state.taskUnderEdit.asset
              && this.state.taskUnderEdit.asset.equipment && this.state.taskUnderEdit.asset.equipment.temperatureControlled == true) {
              validationRules[element.fieldName] = validationRules[element.fieldName].required(`${nameOfField} is required.`);
            } else {
              continue;
            }
          }
          else if (nameOfField.toLocaleLowerCase().includes("reefer")) {
            if (this.state.taskUnderEdit.asset
              && this.state.taskUnderEdit.asset.equipment && this.state.taskUnderEdit.asset.equipment.reefer == true) {
              validationRules[element.fieldName] = validationRules[element.fieldName].required(`${nameOfField} is required.`);
            } else {
              continue;
            }
          }
          else if (nameOfField.toLocaleLowerCase().includes("fuel")) {
            if (this.state.taskUnderEdit.asset
              && this.state.taskUnderEdit.asset.equipment && this.state.taskUnderEdit.asset.equipment.reefer == true) {
              validationRules[element.fieldName] = validationRules[element.fieldName].required(`${nameOfField} is required.`);
            } else {
              continue;
            }
          }
          else if (nameOfField.toLocaleLowerCase().includes("continous")) {
            if (this.state.taskUnderEdit.asset
              && this.state.taskUnderEdit.asset.equipment && this.state.taskUnderEdit.asset.equipment.reefer == true) {
              validationRules[element.fieldName] = validationRules[element.fieldName].required(`${nameOfField} is required.`);
            } else {
              continue;
            }
          }



          else if (nameOfField.toLocaleLowerCase().includes("reference")) {
            if (this.props.currentLocation.apiSetting !== null &&
              this.props.currentLocation.apiSetting !== undefined &&
              this.props.currentLocation.apiSetting.refernceApi) {
              validationRules[element.fieldName] = validationRules[element.fieldName].required(`${nameOfField} is required.`);

            } else {
              continue;
            }
          }
          else {
            if (validationRules[element.fieldName] && element.fieldName !== 'reefer' && element.fieldName !== 'continous') {
              console.log("Field  found in validationRules:", element.fieldName);

              validationRules[element.fieldName] = validationRules[element.fieldName].required(`${nameOfField} is required.`);

            } else {
              console.log("Field not found in validationRules:", element.fieldName);
            }
          }

        }
      }
    }

    /*  this.state.requiredFields.forEach(field => {
        const fieldName = field.fieldName;
        console.log("Processing fieldName:", fieldName); 
        if (validationRules[fieldName]) {
          console.log("Adding required to:", fieldName);
          validationRules[fieldName] = validationRules[fieldName].required(`${fieldName} is required.`);
        } else {
          console.log("Field not found in validationRules:", fieldName); 
        }
      });*/
    return Yup.object().shape(validationRules);
  }
  getLocationFireEvent(info, obj, val, reasonCode?) {
    if (this.state.isLocationRequired) {
      if (navigator.geolocation) {
        let positionOptions = {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 10000,
        };
        this.setState({ loaded: true });
        this.updateSpinner("Loading ...");
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.setState({
              liveLocation: {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
              },
            });
            this.performAction(info, obj, val, reasonCode);
          },
          (error) => {
            this.setState({ loaded: false });
            switch (error.code) {
              case error.PERMISSION_DENIED:
                showError(
                  "User denied the request for Geolocation.Please allow."
                );
                break;
              case error.POSITION_UNAVAILABLE:
                showError(
                  "Location information is unavailable.Please try again!"
                );
                break;
              case error.TIMEOUT:
                showError(
                  "The request to get user location timed out.Please refresh!"
                );
                break;
              case error.UNKNOWN_ERROR:
                showError("An unknown error occurred.");
                break;
            }
          },
          positionOptions
        );
      } else {
        showError("Geolocation is not supported by this browser.");
      }
    }
    else{
      this.performAction(info, obj, val, reasonCode);

    }
  
  }
  performAction(info, task, val, reasonCode?) {
    this.setState({ loaded: true });
    this.updateSpinner(info + " ...");

    let obj = {
      taskUuid: task.uuid,
      taskVersion: task.taskVersion,
      ...(this.state.isLocationRequired && {
          coordinates: {
              latitude: this.state.liveLocation.latitude,
              longitude: this.state.liveLocation.longitude,
          },
      }),
  };
    if (val === "start") {
      putTaskStart(
        obj,
        (res) => {
          showInfo("Task Started.");

          this.loadTask(task.uuid);
        },
        (err) => {
          showError(err);
        }
      );
    }
    if (val === "end") {
      putTaskEnd(
        obj,
        (res) => {
          showInfo("Task Ended.");

          this.loadTask(task.uuid);
        },
        (err) => {
          showError(err);
        }
      );
    }
    if (val === "cancel") {
      let o = {
        uuid: task.uuid,
        latitude: this.state.liveLocation.latitude,
        longitude: this.state.liveLocation.longitude,
        cancelReason: reasonCode,
        taskVersion: task.taskVersion,
      };
      putTaskCancel(
        o,
        (res) => {
          this.setState({ cancelModalOpen: false });
          this.props.history.push("/poweryard-tasks");
        },
        (err) => {
          showError(err);
        }
      );
    }
  }

  timezoneShortCode(timezone, inp) {
    if (!inp) inp = new Date();
    if (timezone) {
      return moment(inp).tz(timezone).format("z");
    } else {
      return moment(inp).tz(moment.tz.guess()).format("z");
    }
  }
  taskProgressChecker(val) {
    this.setState({ taskProgressTypeChecker: val });
  }
  onChangeRefNum(obj) {
    let taskUnderEditObj = { ...this.state.taskUnderEdit };

    getTaskReferenceByRefID(
      obj.value,
      (success) => {
        if (success.code === "200") {
          let response = success.content;
          if (
            this.props.company.temperatureUnitsOfMeasure === "Fahrenheit" ||
            this.props.company.temperatureUnitsOfMeasure !== "Celsius"
          ) {
            response.tempSetPoint = convertCtoF(
              response.temperatureSetPointInC
            );
            if (response.tempSetPoint !== null) {
              response.tempSetPoint = this.getFlooredFixed(
                response.tempSetPoint,
                1
              );
            }
            response.actualTemp = convertCtoF(response.temperatureInC);
            if (response.actualTemp !== null) {
              response.actualTemp = this.getFlooredFixed(
                response.actualTemp,
                1
              );
            }
          }
          if (this.props.company.temperatureUnitsOfMeasure === "Celsius") {
            response.tempSetPoint = response.temperatureSetPointInC;
            if (response.temperatureSetPointInC !== null) {
              response.tempSetPoint = this.getFlooredFixed(
                response.tempSetPoint,
                1
              );
            }
            response.actualTemp = response.temperatureInC;
            if (response.actualTemp !== null) {
              response.actualTemp = this.getFlooredFixed(
                response.actualTemp,
                1
              );
            }
          }
          let task = taskUnderEditObj;
          task = response;
          task.uuid = taskUnderEditObj.uuid;
          task.isNew =
            taskUnderEditObj.isNew === undefined
              ? false
              : taskUnderEditObj.isNew;
          task.acceptDate = taskUnderEditObj.acceptDate;
          task.start = taskUnderEditObj.start;
          task.end = taskUnderEditObj.end;
          task.type = taskUnderEditObj.type;
          var secRefObj = {
            value: obj.value,
            label: obj.label,
            uuid: obj.uuid,
            valueSubmit: task.secondaryRef,
          };
          task.moveTo = task.moveTo === null ? {} : task.moveTo;
          task.secondaryRef = secRefObj;
          this.setState({ taskUnderEdit: task });
          // success.content.isNew = taskUnderEditObj.isNew === undefined ? false : taskUnderEditObj.isNew;
          // success.content.uuid = taskUnderEditObj.uuid;
          // success.content.acceptDate = taskUnderEditObj.acceptDate;
          // success.content.startDate = taskUnderEditObj.startDate;
          // success.content.endDate = taskUnderEditObj.endDate;
          // success.content.type = taskUnderEditObj.type === undefined ? {} : taskUnderEditObj.type;
          // // for temperature converting
          // if (this.props.company.temperatureUnitsOfMeasure === "Fahrenheit" || this.props.company.temperatureUnitsOfMeasure !== "Celsius") {
          //   success.content.tempSetPoint = convertCtoF(success.content.temperatureSetPointInC);
          //   if (success.content.tempSetPoint !== null) {
          //     success.content.tempSetPoint = this.getFlooredFixed(success.content.tempSetPoint, 1);
          //   }
          //   success.content.actualTemp = convertCtoF(success.content.temperatureInC);
          //   if (success.content.actualTemp !== null) {
          //     success.content.actualTemp = this.getFlooredFixed(success.content.actualTemp, 1);
          //   }
          // }
          // if (this.props.company.temperatureUnitsOfMeasure === "Celsius") {
          //   success.content.tempSetPoint = success.content.temperatureSetPointInC;
          //   if (success.content.temperatureSetPointInC !== null) {
          //     success.content.tempSetPoint = this.getFlooredFixed(success.content.tempSetPoint, 1);
          //   }
          //   success.content.actualTemp = success.content.temperatureInC;
          //   if (success.content.actualTemp !== null) {
          //     success.content.actualTemp = this.getFlooredFixed(success.content.actualTemp, 1);
          //   }
          // }
          // var secRefObj = {
          //   value: obj.value,
          //   label: obj.label,
          //   uuid: obj.uuid,
          //   valueSubmit: success.content.secondaryRef
          // }
          // success.content.moveTo = success.content.moveTo === null ? {} : success.content.moveTo;
          // success.content.secondaryRef = secRefObj;
          // this.setState({ taskUnderEdit: success.content, showSealNumber: success.content.sealed })
        } else if (success.code === "500") {
          this.setState({
            taskUnderEdit: {
              start: new Date(taskUnderEditObj.start),
              type:
                taskUnderEditObj.type === undefined
                  ? {}
                  : taskUnderEditObj.type,
            },
            sealed: false,
            showSealNumber: false,
          });
        } else if (success.code === "1000") {
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  openRefModal(ref, elem) {
    this.setState({ loaded: true });
    this.updateSpinner("Loading...");
    ref.clickedRef = elem;
    this.onGetRefDataByRefID(ref);
  }
  onGetRefDataByRefID(ref) {
    getSecondaryReferenceByRefID(
      ref.clickedRef,
      (success) => {
        if (success.code === "200") {
          ref.refData = success.content;
          this.setState({
            isRefModalOpen: true,
            selectedTask: ref,
            loaded: false,
          });
        } else if (success.code === "1000") {
          showError(success.message);
          this.setState({ loaded: false });
        }
      },
      (error) => {
        this.setState({ loaded: false });
        showError(error.message);
        console.log(error);
      }
    );
  }

  taskRemoveAssignee(obj) {
    confirmAlert({
      title: "Confirm Unassign",
      message: `Are you sure to unassign ${this.state.taskUnderEdit.assignee.firstname} ${this.state.taskUnderEdit.assignee.lastname} from task.`,
      buttons: [
        {
          label: "No",
          className: "btn danger",
          onClick: () => { },
        },
        {
          label: "Yes",
          className: "btn btn-primary",
          onClick: () => {
            this.setState({ loaded: true });

            this.updateSpinner("Unassigning ...");
            putTaskRemoveAssignee(
              this.state.taskUnderEdit.uuid,
              this.state.taskUnderEdit.taskVersion,
              this.props.position,
              (response) => {
                this.setState({ loaded: false });
                console.log(response);
                this.props.history.push("/poweryard-tasks");
                showInfo(
                  "The task has been unassigned and any start time cleared."
                );
              },
              (error) => {
                this.setState({ loaded: false });
                console.log(error);
              }
            );
          },
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
      overlayClassName: "overlay-custom-class-name",
    });
    // this.setState({ alertMsgStr: 'Are you sure to unassign this task?', actionBtnStr: 'Yes', alertFor: 'UnassignTask', isConfirmModalOpen: true });
  }

  handleSubmit = (e, val?) => {
    console.log("in handle ", this.state.taskUnderEdit)
    let s = { ...this.state.taskUnderEdit };
    let errors = this.validate(this.validationSchema.bind(this))(
      this.state.taskUnderEdit
    );
    let closeCheck = this.state.closeCheck;
    let errorsIsEmpty =
      Object.keys(errors).length === 0 &&
      errors.constructor === Object;

    if (!errorsIsEmpty || this.state.errors.name) {
      let t = { ...this.state.touched };
      t.type = true;
      // t.assetType = true;

      if (this.state.requiredFields && this.state.requiredFields.length > 0) {
        for (let index = 0; index < this.state.requiredFields.length; index++) {
          const element = this.state.requiredFields[index];
          let field = element.fieldName;
          let nameOfField = fieldnamesProgress.find(
            (it) => it.value == field
          ).label;
          if (nameOfField.toLocaleLowerCase().includes("temperature")) {
            if (this.state.taskUnderEdit.asset
              && this.state.taskUnderEdit.asset.equipment && this.state.taskUnderEdit.asset.equipment.temperatureControlled == true) {

              t[field] = true;
            }
            else {
              continue;
            }
          } else if (nameOfField.toLocaleLowerCase().includes("reference")) {
            if (this.props.currentLocation.apiSetting !== null &&
              this.props.currentLocation.apiSetting !== undefined &&
              this.props.currentLocation.apiSetting.refernceApi) {
              t[field] = true;

            } else {
              continue;
            }
          }
          else {
            t[field] = true;
          }



        }

      }

      this.setState({ touched: t, errors: errors });
      e.preventDefault();

      return;
    }
    this.onSubmit(val);
    e.preventDefault();
  }

  render() {
    return (
      <div ref={this.ref}>
        {this.state.selectedTask !== undefined && (
          <ReferenceModal
            selectedTask={this.state.selectedTask}
            currentLocation={this.props.currentLocation}
            cancel={() => this.setState({ isRefModalOpen: false })}
            isRefModalOpen={this.state.isRefModalOpen}
          />
        )}
        {/* <ConfirmModal
          alertFor={this.state.alertFor}
          alertMsgStr={this.state.alertMsgStr}
          actionBtnStr={this.state.actionBtnStr}
          onConfirm={(alertFor) => {
            alert('alertFor', alertFor)
          }}
          cancel={() => this.setState({ isConfirmModalOpen: false })}
          isConfirmModalOpen={this.state.isConfirmModalOpen}>
        </ConfirmModal> */}
        <Loader show={this.state.loaded} message={this.spinner}>
          <div className="text-left p-5">
            <CancelTaskModal
              open={this.state.cancelModalOpen}
              defaultReasonCode={this.state.defaultCancelReasonCode}
              onSave={(reasonCode) => {
                this.getLocationFireEvent(
                  "",
                  this.state.taskUnderEdit.uuid,
                  "cancel",
                  reasonCode
                );
                showInfo("Task Canceled");
              }}
              onCancel={() => this.setState({ cancelModalOpen: false })}
            />
            {/* <TemperatureModal
              open={this.state.temperatureModalOpen}
              company={this.props.company}
              taskUnderEdit={this.state.taskUnderEdit}
              requiredFields={this.state.requiredFields}
              onSave={(taskUnderEdit) => {
                taskUnderEdit.type = "temperature";
                // let errors = this.validate(this.validationSchema.bind(this))(
                //   this.state.taskUnderEdit
                // );
                // let closeCheck = this.state.closeCheck;
                // let errorsIsEmpty =
                //   Object.keys(errors).length === 0 && errors.constructor === Object;
                // if (!errorsIsEmpty || this.state.errors.name) {
                //   let t = { ...this.state.touched };
                //   t.actualTemp = true;
                //   t.tempSetPoint = true;
                //   this.setState({ touched: t, errors: errors });
                //   // e.preventDefault();
                //   return;
                // }
                this.onSubmit("temp", taskUnderEdit);
                // e.preventDefault();
                // this.setState({taskUnderEdit: taskUnderEdit});
              }}
              onCancel={() => this.setState({ temperatureModalOpen: false })}
            /> */}
            {/* <CommentTaskModal
              open={this.state.commentTaskModalOpen}
              company={this.props.company}
              taskUnderEdit={this.state.taskUnderEdit}
              required={(this.state.taskUnderEdit.taskStatus === "Complete" && this.getRequiredCheck("comments"))? true : false}
              onSave={(taskUnderEdit) => {
                // this.setState({taskUnderEdit: taskUnderEdit});
                taskUnderEdit.type = "comments";
                let errors = this.validate(this.validationSchema.bind(this))(
                  this.state.taskUnderEdit
                );

                let errorsIsEmpty =
                  Object.keys(errors).length === 0 &&
                  errors.constructor === Object;
                if (!errorsIsEmpty || this.state.errors.name) {
                  let t = { ...this.state.touched };
                  t.type = true;
                  t.assetType = true;
                  this.setState({ touched: t, errors: errors });
                  // e.preventDefault();
                  return;
                }
                this.onSubmit("comment", taskUnderEdit);
               
              }}
              onCancel={() => this.setState({ commentTaskModalOpen: false })}
            /> */}
            <h5 className="dispatch-title">
              <Button
                hidden={this.state.taskUnderEdit.end}
                onClick={() =>
                  this.taskRemoveAssignee(this.state.taskUnderEdit.assignee)
                }
                color={"danger"}
              >
                {this.state.taskUnderEdit.assignee
                  ? this.state.taskUnderEdit.assignee.firstname +
                  " " +
                  this.state.taskUnderEdit.assignee.lastname
                  : ""}{" "}
                <i className="fa fa-times-circle" />
              </Button>{" "}
              &nbsp; TASK IN PROGRESS
              <span className="float-right">
                {this.props.currentLocation.nickname}
              </span>
            </h5>

            <div>
              <Form
                onSubmit={this.handleSubmit}
              >
                <Card className="simple-card">
                  <CardBody>
                    <div>
                      <FormGroup>
                        {this.state.taskUnderEdit.start === null && (
                          <Row>
                            {/* <Col lg="2" md="3" sm="4">
                            </Col> */}

                            <Col className="text-center" lg="12" md="10" sm="8">
                              <Label className="mr-3">Click to Start</Label>
                              <Button
                                className={"progressScreenBtn"}
                                type="submit"
                                onClick={() =>
                                  this.taskProgressChecker("start")
                                }
                                color="primary"
                              >
                                {" "}
                                <i className="fa fa-clock" /> Begin Task
                              </Button>
                            </Col>
                          </Row>
                        )}
                        {this.state.taskUnderEdit.start !== null && (
                          <Row>
                            <Col lg="2" md="3" sm="4">
                              <Label>
                                {"Task Start Timestamp (" +
                                  this.timezoneShortCode(
                                    this.props.currentLocation &&
                                    this.props.currentLocation.timezone,
                                    this.state.taskUnderEdit.start
                                  ) +
                                  ")"}
                              </Label>
                            </Col>
                            <Col className={"task-form"} lg="10" md="9" sm="8">
                              <DatePicker
                                placeholderText={"MM/DD/YYYY HH:MM AP"}
                                className={
                                  this.state.errors &&
                                  this.state.errors.start &&
                                  "is-invalid"
                                }
                                selected={this.state.taskUnderEdit.start}
                                onChange={(date) => {
                                  let s = { ...this.state.taskUnderEdit };
                                  s.start = date;
                                  // if the date is invalid because the user cleared the time component, calling setState will result in infinite recursion
                                  if (date === null || !isNaN(date.getTime())) {
                                    this.setState({ taskUnderEdit: s });
                                  }
                                }}
                                timeInputLabel="Time:"
                                dateFormat="MM/dd/yyyy h:mm a"
                                showTimeInput
                                shouldCloseOnSelect={false}
                              />
                            </Col>
                          </Row>
                        )}
                      </FormGroup>
                      <FFFormAsyncSelectField
                        key={"ID_taskType"}
                        defaultOptions={true}
                        fieldname="type"
                        label="Task Type"
                        hideEmpty={true}
                        placeholder="Select the Task Type from Drop-Down (required)"
                        icon="fa-keynote"
                        value={
                          this.state.taskUnderEdit.type
                            ? this.state.taskUnderEdit.type.name
                            : ""
                        }
                        required={true}
                        loadOptions={promiseTaskTypeByLocationUUid(
                          this.props.currentLocation.uuid
                        )}
                        onBlur={() => {
                          let errors = this.validate(
                            this.validationSchema.bind(this)
                          )(this.state.taskUnderEdit);
                          let touched = { ...this.state.touched };
                          touched.type = true;
                          this.setState({ errors: errors, touched: touched });
                        }}
                        error={this.state.errors.type}
                        touched={this.state.touched.type}
                        onChange={(v) => {
                          let s = { ...this.state.taskUnderEdit };
                          s.type = v;
                          this.setState({ taskUnderEdit: s });
                        }}
                      />
                      <FFFormAsyncSelectField
                        defaultOptions={true}
                        fieldname="carrier"
                        label="Trailer Name"
                        hideEmpty={true}
                        placeholder="Select the Trailer Carrier Name (from the list)"
                        icon="fa-truck"
                        value={
                          this.state.taskUnderEdit.asset &&
                            this.state.taskUnderEdit.asset.carrier
                            ? this.state.taskUnderEdit.asset.carrier.name
                            : this.state.taskUnderEdit.carrier
                              ? this.state.taskUnderEdit.carrier.name
                              : ""
                        }
                        loadOptions={carriersByLocationUUid(
                          this.props.currentLocation.uuid
                        )}
                        checkered={true}
                        required={(this.state.taskUnderEdit.taskStatus === "Complete" && this.getRequiredCheck("carrier")) ? true : false}
                        onBlur={() => {
                          let errors = this.validate(
                            this.validationSchema.bind(this)
                          )(this.state.taskUnderEdit);
                          let touched = { ...this.state.touched };
                          touched.carrier = true;
                          this.setState({ errors: errors, touched: touched });
                        }}
                        error={this.state.errors.carrier}
                        touched={this.state.touched.carrier}
                        onChange={(v) => {
                          let s = { ...this.state.taskUnderEdit };
                          if (
                            v == null ||
                            (s.carrier && s.carrier.uuid !== v.uuid)
                          ) {
                            s.asset = {};
                          }
                          if (v && v.__isNew__) {
                            v.name = v.label;
                          }
                          if (s.asset) {
                            s.asset.carrier = v;
                          }
                          s.carrier = v;
                          this.setState({ taskUnderEdit: s });
                        }}
                      />
                      {/* <FFFormSelectCreatableField
                        defaultOptions={true}
                        fieldname="carrier"
                        label="Trailer Name"
                        hideEmpty={true}
                        placeholder="Select the Trailer Carrier Name (from the list)"
                        icon="fa-truck"
                        value={
                          this.state.taskUnderEdit.asset &&
                            this.state.taskUnderEdit.asset.carrier
                            ? this.state.taskUnderEdit.asset.carrier.name
                            : this.state.taskUnderEdit.carrier
                              ? this.state.taskUnderEdit.carrier.name
                              : ""
                        }
                        loadOptions={carriersByLocationUUid(
                          this.props.currentLocation.uuid
                        )}
                        
                        checkered={true}
                        required={(this.state.taskUnderEdit.taskStatus === "Complete" && this.getRequiredCheck())? true : false}
                        onBlur={() => {
                          let errors = this.validate(
                            this.validationSchema.bind(this)
                          )(this.state.taskUnderEdit);
                          let touched = { ...this.state.touched };
                          touched.carrier = true;
                          this.setState({ errors: errors, touched: touched });
                        }}
                        error={this.state.errors.carrier}
                        touched={this.state.touched.carrier}
                        onChange={(v) => {
                          let s = { ...this.state.taskUnderEdit };
                          if (
                            v == null ||
                            (s.carrier && s.carrier.uuid !== v.uuid)
                          ) {
                            s.asset = {};
                          }
                          if (v && v.__isNew__) {
                            v.name = v.label;
                          }
                          if (s.asset) {
                            s.asset.carrier = v;
                          }
                          s.carrier = v;
                          this.setState({ taskUnderEdit: s });
                        }}
                      /> */}
                      <FFFormSelectCreatableField
                        key={
                          "ID_" +
                          (this.state.taskUnderEdit.carrier
                            ? this.state.taskUnderEdit.carrier.uuid
                            : "")
                        }
                        fieldname="assetId"
                        label="Trailer Number"
                        hideEmpty={true}
                        placeholder="Input/Select Trailer Number (Type Ahead, Drop Down),Select Trailer Name to Enable"
                        icon="fa-truck"
                        value={
                          this.state.taskUnderEdit.asset && this.state.taskUnderEdit.asset.assetId
                            ? this.state.taskUnderEdit.asset.assetId.replace(/ /g, '').toUpperCase()
                            : ""
                        }
                        isClearable={true}
                        isDisabled={!this.state.taskUnderEdit.carrier}
                        defaultOptions={true}
                        loadOptions={promiseAssetByPrefix(
                          this.state.taskUnderEdit.carrier
                            ? this.state.taskUnderEdit.carrier.uuid
                            : ""
                        )}
                        checkered={true}
                        required={(this.state.taskUnderEdit.taskStatus === "Complete" && this.getRequiredCheck("assetId")) ? true : false}
                        onBlur={() => {
                          let errors = this.validate(
                            this.validationSchema.bind(this)
                          )(this.state.taskUnderEdit);
                          let touched = { ...this.state.touched };
                          touched.assetId = true;
                          this.setState({ errors: errors, touched: touched });
                        }}
                        error={this.state.errors.assetId}
                        touched={this.state.touched.assetId}
                        onChange={(v) => {
                          let s = { ...this.state.taskUnderEdit };
                          s.assetId = v;
                          s.asset = v;
                          s.assetType =
                            v && v.equipment ? v.equipment.name : "";
                          if (v && v.__isNew__ === true) {
                            s.asset.assetId = s.asset.assetId.replace(/ /g, '').toUpperCase()
                            s.asset.label = s.asset.label.replace(/ /g, '').toUpperCase()
                            s.asset.value = s.asset.value.replace(/ /g, '').toUpperCase()
                          }
                          if (
                            v &&
                            v.latestTaskSpot != null &&
                            v.latestTaskSpot.location &&
                            v.latestTaskSpot.location.uuid ===
                            this.props.currentLocation.uuid
                          ) {
                            s.moveFrom = v.latestTaskSpot;
                          }

                          if( v.equipment&&!v.equipment.temperatureControlled){
                              s.tempSetPoint = null;
                              s.actualTemp = null;

                            }
                            if ( v.equipment&&!v.equipment.reefer) {
                              s.fuelLevel = null;
                              s.reefer = null;
                              s.continous = null;

                            }
                          this.setState({ taskUnderEdit: s });
                        }}
                      ></FFFormSelectCreatableField>

                      <FFFormAsyncSelectField
                        defaultOptions={true}
                        fieldname="assetType"
                        label="Asset Type"
                        icon="fa-spot"
                        //btnIcon="fa-temperature-low"
                        value={this.getAssetType()}
                        required={(this.state.taskUnderEdit.taskStatus === "Complete" && this.getRequiredCheck("assetType")) ? true : false}
                        isClearable={false}
                        hideEmpty={true}
                        //button
                        // btnHidden={
                        //   (this.state.taskUnderEdit.asset &&
                        //     this.state.taskUnderEdit.asset.equipment &&
                        //     this.state.taskUnderEdit.asset.equipment
                        //       .temperatureControlled) ||
                        //   (this.state.taskUnderEdit.assetType &&
                        //     this.state.taskUnderEdit.assetType
                        //       .temperatureControlled)
                        // }
                        placeholder="Asset Type - Select the Trailer Type"
                        // btnEmptyState= {this.getButtonState()}
                        onClick={() => {
                          this.setState({
                            cancelTask: this.state.taskUnderEdit,
                            temperatureModalOpen: true,
                          });
                        }}
                        // placeholder={this.state.defaultEquipmentType}
                        loadOptions={
                          this.props.currentLocation
                            ? promiseEZTrackEquipmentTypeByPrefix(
                              this.props.currentLocation.uuid
                            )
                            : promiseEZTrackEquipmentTypeByPrefix
                        }
                        onBlur={() => {
                          let errors = this.validate(
                            this.validationSchema.bind(this)
                          )(this.state.taskUnderEdit);
                          let touched = { ...this.state.touched };
                          touched.assetType = true;
                          this.setState({ errors: errors, touched: touched });
                        }}
                        error={this.state.errors.assetType}
                        touched={this.state.touched.assetType}
                        onChange={(v) => {
                          let s = { ...this.state.taskUnderEdit };
                          if (s.asset) {
                            s.asset.equipment =
                              v === null ? this.state.defaultEquipmentType : v;
                          }
                          s.assetType = v;
                          this.setState({ taskUnderEdit: s });
                        }}
                      />
                      {this.props.currentLocation.apiSetting !== null &&
                        this.props.currentLocation.apiSetting !== undefined &&
                        this.props.currentLocation.apiSetting.refernceApi && (
                          <FFFormLabelButton
                            label=""
                            isDisabled={
                              this.state.secRefNumber === null ? true : false
                            }
                            btnColor={"primary"}
                            isLblShow={false}
                            btnTxt="Reference Data"
                            fieldname="clientSecret"
                            onClick={() =>
                              this.openRefModal(
                                this.state.selectedTask.refData,
                                this.state.taskUnderEdit.secondaryRef.value
                              )
                            }
                          />
                        )}

                      {((this.state.taskUnderEdit.asset &&
                        this.state.taskUnderEdit.asset.equipment &&
                        this.state.taskUnderEdit.asset.equipment
                          .reefer) ||
                        (this.state.taskUnderEdit.assetType &&
                          this.state.taskUnderEdit.assetType
                            .reefer || this.state.newAsset === true) ||
                        this.state.newAsset === true) && (
                          <>

                            <FFFormAsyncSelectField
                              defaultOptions={true}
                              fieldname="fuelLevel"
                              label="Fuel Level"
                              hideEmpty={true}
                              required={(this.state.taskUnderEdit.taskStatus === "Complete" && this.getRequiredCheck("fuelLevel")) ? true : false}
                              placeholder="Select the fuel Level of the trailer (from the list)"
                              icon="fa-solid fa-gas-pump"
                              isClearable={true}
                              value={
                                this.state.taskUnderEdit.fuelLevel
                                  ? this.state.taskUnderEdit.fuelLevel.label
                                  : ""
                              }
                              loadOptions={promiseEZTrackEquipmentFuel}
                              onBlur={() => {
                                let errors = this.validate(
                                  this.validationSchema.bind(this)
                                )(this.state.taskUnderEdit);
                                let touched = { ...this.state.touched };

                                touched.fuelLevel = true;
                                this.setState({ errors: errors, touched: touched });
                              }}
                              error={this.state.errors.fuelLevel}
                              touched={this.state.touched.fuelLevel}
                              onChange={(v) => {
                                let s = { ...this.state.taskUnderEdit };
                                s.fuelLevel = v;
                                this.setState({ taskUnderEdit: s });
                              }}
                            />
                            <FFFormCheckboxField
                              fieldname="reefer"
                              icon="fa-power-off"

                              label="Reefer (On/Off )"
                              required={(this.state.taskUnderEdit.taskStatus === "Complete" && this.getRequiredCheck("reefer")) ? true : false}
                              value={
                                this.state.taskUnderEdit.reefer == null
                                  ? false
                                  : this.state.taskUnderEdit.reefer
                              }
                              onChange={(v) => {
                                let s = { ...this.state.taskUnderEdit };
                                s.reefer = v;
                                if (!v) { // If Reefer is false, set Continous to false as well
                                  s.continous = false;
                                }
                                this.setState({
                                  taskUnderEdit: s,
                                });
                              }}
                            />
                            <FFFormCheckboxField
                              fieldname="continous"
                              icon="fa-power-off"
                              label="Continuous (Yes/No)"

                              disabled={!this.state.taskUnderEdit.reefer}
                              required={(this.state.taskUnderEdit.taskStatus === "Complete" && this.getRequiredCheck("continous")) ? true : false}
                              value={
                                this.state.taskUnderEdit.continous == null
                                  ? false
                                  : this.state.taskUnderEdit.continous
                              }
                              onChange={(v) => {
                                let s = { ...this.state.taskUnderEdit };
                                s.continous = v;
                                this.setState({
                                  taskUnderEdit: s,
                                });
                              }}
                            />

                          </>
                        )}
                      {((this.state.taskUnderEdit.asset &&
                        this.state.taskUnderEdit.asset.equipment &&
                        this.state.taskUnderEdit.asset.equipment
                          .temperatureControlled) ||
                        (this.state.taskUnderEdit.assetType &&
                          this.state.taskUnderEdit.assetType
                            .temperatureControlled) ||
                        this.state.newAsset === true) && (
                          <>    <FFFormTextField
                            fieldname="tempSetPoint"
                            isClearable={true}

                            label={
                              "Temp - Set Point (" +
                              (this.props.company.temperatureUnitsOfMeasure ===
                                "Fahrenheit"
                                ? "℉"
                                : "℃") +
                              ")"
                            }
                            placeholder="Enter the temperature set point"
                            icon="fa-temperature-low"
                            value={this.state.taskUnderEdit.tempSetPoint}
                            numbersOnly={false}
                            required={(this.state.taskUnderEdit.taskStatus === "Complete" && this.getRequiredCheck("tempSetPoint")) ? true : false}

                            onBlur={() => {
                              let errors = this.validate(
                                this.validationSchema.bind(this)
                              )(this.state.taskUnderEdit);
                              let touched = { ...this.state.touched };
                              touched.tempSetPoint = true;
                              this.setState({ errors: errors, touched: touched });
                            }}
                            error={this.state.errors.tempSetPoint}
                            touched={this.state.touched.tempSetPoint}
                            onChange={(v) => {
                              let s = { ...this.state.taskUnderEdit };
                              s.tempSetPoint = v;
                              this.setState({ taskUnderEdit: s });
                            }}
                          />
                            <FFFormTextField
                              fieldname="actualTemp"
                              isClearable={true}
                              label={
                                "Temp - Actual (" +
                                (this.props.company.temperatureUnitsOfMeasure ===
                                  "Fahrenheit"
                                  ? "℉"
                                  : "℃") +
                                ")"
                              }
                              placeholder="Enter the actual temperature"
                              icon="fa-temperature-high"
                              value={this.state.taskUnderEdit.actualTemp}
                              numbersOnly={false}
                              required={(this.state.taskUnderEdit.taskStatus === "Complete" && this.getRequiredCheck("actualTemp")) ? true : false}
                              onBlur={() => {
                                let errors = this.validate(
                                  this.validationSchema.bind(this)
                                )(this.state.taskUnderEdit);
                                let touched = { ...this.state.touched };
                                touched.actualTemp = true;
                                this.setState({ errors: errors, touched: touched });
                              }}
                              error={this.state.errors.actualTemp}
                              touched={this.state.touched.actualTemp}
                              onChange={(v) => {
                                let s = { ...this.state.taskUnderEdit };
                                s.actualTemp = v;
                                this.setState({ taskUnderEdit: s });
                              }}
                            />
                          </>

                        )}
                      {this.props.currentLocation.apiSetting !== null &&
                        this.props.currentLocation.apiSetting !== undefined &&
                        this.props.currentLocation.apiSetting.refernceApi && (
                          <FFFormAsyncSelectField
                            defaultOptions={false}
                            isClearable={true}
                            fieldname="secondaryRef"
                            label="Reference Number"
                            isDisabled={false}
                            hideEmpty={true}
                            placeholder="Select Your Company ID for the Shipment on this Trailer"
                            icon="fa-regular fa-hashtag"
                            value={
                              this.state.taskUnderEdit.secondaryRef
                                ? this.state.taskUnderEdit.secondaryRef
                                  .valueSubmit
                                : ""
                            }
                            required={(this.state.taskUnderEdit.taskStatus === "Complete" && this.getRequiredCheck("secondaryRef")) ? true : false}
                            loadOptions={
                              this.state.taskUnderEdit.location
                                ? getAllSecondaryReferenceByRefID(
                                  this.state.taskUnderEdit.location.uuid
                                )
                                : getAllSecondaryReferenceByRefID
                            }
                            // isDisabled={this.state.secRefNumber}
                            onBlur={() => {
                              let errors = this.validate(
                                this.validationSchema.bind(this)
                              )(this.state.taskUnderEdit);
                              let touched = { ...this.state.touched };
                              touched.secondaryRef = true;
                              this.setState({
                                errors: errors,
                                touched: touched,
                              });
                            }}
                            error={this.state.errors.secondaryRef}
                            touched={this.state.touched.secondaryRef}
                            onChange={(v) => {
                              let s = { ...this.state.taskUnderEdit };
                              s.secondaryRef = v;
                              if (v !== null) {
                                if (this.state.isNew) {
                                  this.onChangeRefNum(v);
                                }
                                this.setState({
                                  taskUnderEdit: s,
                                  secRefNumber: v.valueSubmit,
                                });
                              } else {
                                this.setState({
                                  taskUnderEdit: s,
                                  secRefNumber: null,
                                });
                              }
                            }}
                          />
                        )}
                      <FFFormAsyncSelectField
                        defaultOptions={true}
                        fieldname="assetStatus"
                        label="Asset Status"
                        hideEmpty={true}
                        placeholder="Select the status of the trailer (from the list)"
                        icon="fa-balance-scale-left"
                        isClearable={true}
                        value={
                          this.state.taskUnderEdit.assetStatus
                            ? this.state.taskUnderEdit.assetStatus.name
                            : ""
                        }
                        required={(this.state.taskUnderEdit.taskStatus === "Complete" && this.getRequiredCheck("assetStatus")) ? true : false}
                        loadOptions={promiseAssetStatusesByLocationUUid(
                          this.props.currentLocation.uuid
                        )}
                        onBlur={() => {
                          let errors = this.validate(
                            this.validationSchema.bind(this)
                          )(this.state.taskUnderEdit);
                          let touched = { ...this.state.touched };

                          touched.assetStatus = true;
                          this.setState({ errors: errors, touched: touched });
                        }}
                        error={this.state.errors.assetStatus}
                        touched={this.state.touched.assetStatus}
                        onChange={(v) => {
                          let s = { ...this.state.taskUnderEdit };
                          s.assetStatus = v;
                          this.setState({ taskUnderEdit: s });
                        }}
                      />
                      <FFFormAsyncSelectField
                        defaultOptions={true}
                        isClearable={true}
                        fieldname="moveFrom"
                        label="Move From Spot"
                        placeholder="Select Move From Spot (Type Ahead, Drop Down)"
                        icon="fa-play"
                        value={
                          this.state.taskUnderEdit.moveFrom
                            ? this.state.taskUnderEdit.moveFrom.uuid &&
                              this.state.taskUnderEdit.moveFrom.name
                              ? this.state.taskUnderEdit.moveFrom.zone +
                              " - " +
                              this.state.taskUnderEdit.moveFrom.name
                              : this.state.taskUnderEdit.moveFrom.zone
                            : null
                        }
                        required={(this.state.taskUnderEdit.taskStatus === "Complete" && this.getRequiredCheck("moveFrom")) ? true : false}
                        hideEmpty={true}
                        loadOptions={
                          this.props.currentLocation &&
                          promiseSpotForLocationByZonePrefixOrSpotPrefix(
                            this.props.currentLocation.uuid
                          )
                        }
                        onBlur={() => {
                          let errors = this.validate(
                            this.validationSchema.bind(this)
                          )(this.state.taskUnderEdit);
                          let touched = { ...this.state.touched };
                          touched.moveFrom = true;
                          this.setState({ errors: errors, touched: touched });
                        }}
                        error={this.state.errors.moveFrom}
                        touched={this.state.touched.moveFrom}
                        onChange={(v) => {
                          let s = { ...this.state.taskUnderEdit };
                          s.moveFrom = v;
                          let touched = { ...this.state.touched };
                          touched.moveFrom = true;
                          this.setState({ taskUnderEdit: s, touched: touched });
                        }}
                      />
                      <FFFormTextField
                        fieldname="boxedIn"
                        label={"Boxed In"}
                        placeholder="Enter the number of boxing assets"
                        icon="fa-box"
                        value={this.state.taskUnderEdit.boxedIn}
                        numbersOnly={true}
                        isClearable={true}
                        required={(this.state.taskUnderEdit.taskStatus === "Complete" && this.getRequiredCheck("boxedIn")) ? true : false}
                        onBlur={() => {
                          let errors = this.validate(
                            this.validationSchema.bind(this)
                          )(this.state.taskUnderEdit);
                          let touched = { ...this.state.touched };
                          touched.boxedIn = true;
                          this.setState({ errors: errors, touched: touched });
                        }}
                        error={this.state.errors.boxedIn}
                        touched={this.state.touched.boxedIn}
                        onChange={(v) => {
                          let s = { ...this.state.taskUnderEdit };
                          s.boxedIn = v;
                          this.setState({ taskUnderEdit: s });
                        }}
                        max={9}
                        min={1}
                      />
                      <FFFormAsyncSelectField
                        defaultOptions={true}
                        isClearable={true}
                        fieldname="moveTo"
                        label="Move To Spot"
                        hideEmpty={true}
                        placeholder="Select Move To Spot (Type Ahead,  Drop Down)"
                        icon="fa-stop"
                        value={
                          this.state.taskUnderEdit.moveTo
                            ? this.state.taskUnderEdit.moveTo.uuid
                              ? this.state.taskUnderEdit.moveTo.zone +
                              " - " +
                              this.state.taskUnderEdit.moveTo.name
                              : this.state.taskUnderEdit.moveTo.zone
                            : null
                        }
                        required={(this.state.taskUnderEdit.taskStatus === "Complete" && this.getRequiredCheck("moveTo")) ? true : false}
                        loadOptions={
                          this.props.currentLocation &&
                          promiseSpotForLocationByZonePrefixOrSpotPrefix(
                            this.props.currentLocation.uuid
                          )
                        }
                        onBlur={() => {
                          let touched = { ...this.state.touched };
                          touched.moveTo = true;
                          let errors = this.validate(
                            this.validationSchema.bind(this)
                          )(this.state.taskUnderEdit);
                          this.setState({ errors: errors, touched: touched });
                        }}
                        error={this.state.errors.moveTo}
                        touched={this.state.touched.moveTo}
                        onChange={(v) => {
                          let s = { ...this.state.taskUnderEdit };
                          s.moveTo = v;
                          let touched = { ...this.state.touched };
                          touched.moveTo = true;
                          this.setState({ taskUnderEdit: s, touched: touched });
                        }}
                      />
                      <FFFormTextField
                        fieldname="shipmentNumber"
                        label="Shipment Number"
                        placeholder="Enter the shipment number"
                        icon="fa-tag"
                        isClearable={true}
                        value={
                          this.state.taskUnderEdit.shipmentNumber
                            ? this.state.taskUnderEdit.shipmentNumber
                            : ""
                        }
                        required={(this.state.taskUnderEdit.taskStatus === "Complete" && this.getRequiredCheck("shipmentNumber")) ? true : false}
                        onBlur={() => {
                          let errors = this.validate(
                            this.validationSchema.bind(this)
                          )(this.state.taskUnderEdit);
                          let touched = { ...this.state.touched };
                          touched.shipmentNumber = true;
                          this.setState({ errors: errors, touched: touched });
                        }}
                        error={this.state.errors.shipmentNumber}
                        touched={this.state.touched.shipmentNumber}
                        onChange={(v) => {
                          let s = { ...this.state.taskUnderEdit };
                          s.shipmentNumber = v;
                          this.setState({ taskUnderEdit: s });
                        }}
                      />

                      <FFFormCheckboxField
                        button
                        fieldname="sealed"
                        icon="fa-power-off"
                        //btnIcon="fa-sticky-note"
                        required={(this.state.taskUnderEdit.taskStatus === "Complete" && this.getRequiredCheck("sealed")) ? true : false}

                        onClick={(v) => {
                          this.setState({
                            cancelTask: this.state.taskUnderEdit,
                            commentTaskModalOpen: true,
                          });
                        }}
                        additionalText={this.state.errors.sealNumber ? "Turn Sealed True to add seal number" : ""}
                        // btnEmpty={this.state.taskUnderEdit.comments}
                        // btnEmptyState= {this.getButtonState()}
                        label="Sealed"
                        value={
                          this.state.taskUnderEdit.sealed == null
                            ? false
                            : this.state.taskUnderEdit.sealed
                        }
                        onChange={(v) => {
                          let s = { ...this.state.taskUnderEdit };
                          s.sealed = v;
                          this.setState({
                            taskUnderEdit: s,
                            showSealNumber: v,
                          });
                        }}
                      />
                      {this.state.taskUnderEdit.sealed && (
                        <FFFormTextField
                          fieldname="sealNumber"
                          isClearable={true}
                          label="Seal #"
                          placeholder="Enter the seal number"
                          icon="fa-tag"
                          value={
                            this.state.taskUnderEdit.sealNumber
                              ? this.state.taskUnderEdit.sealNumber
                              : ""
                          }
                          required={(this.state.taskUnderEdit.taskStatus === "Complete" && this.getRequiredCheck("sealNumber")) ? true : false}
                          onBlur={() => {
                            let errors = this.validate(
                              this.validationSchema.bind(this)
                            )(this.state.taskUnderEdit);
                            let touched = { ...this.state.touched };
                            touched.sealNumber = true;
                            this.setState({ errors: errors, touched: touched });
                          }}
                          error={this.state.errors.sealNumber}
                          touched={this.state.touched.sealNumber}
                          onChange={(v) => {
                            let s = { ...this.state.taskUnderEdit };
                            s.sealNumber = v;
                            this.setState({ taskUnderEdit: s });
                          }}
                        />
                      )}
                      <FFFormTextAreaField
                        label="Comments"
                        required={(this.state.taskUnderEdit.taskStatus === "Complete" && this.getRequiredCheck("comments")) ? true : false}
                        placeholder="Enter Task Comments"
                        icon="fa-comment"
                        fieldname="comments"
                        isClearable={true}

                        error={this.state.errors.comments}
                        onBlur={() => {
                          let errors = this.validate(
                            this.validationSchema.bind(this)
                          )(this.state.taskUnderEdit);
                          let touched = { ...this.state.touched };
                          touched.comments = true;
                          this.setState({ errors: errors, touched: touched });
                        }}
                        touched={this.state.touched.comments}
                        value={this.state.taskUnderEdit.comments}
                        onChange={(event) => {

                          let s = { ...this.state.taskUnderEdit };
                          s.comments = event !== null ? event.target.value : null;
                          this.setState({ taskUnderEdit: s });
                        }}
                        rows={"5"}
                      />
                      {!this.state.taskUnderEdit.end && (
                        <Row>
                          {/* <Col lg="2" md="3" sm="4">
                          </Col> */}
                          <Col className="text-center" lg="12" md="10" sm="8">
                            <Label className="mr-3">Click to End</Label>
                            <Button
                              className={"progressScreenBtn"}
                              color="primary"
                              disabled={!this.state.taskUnderEdit.start}
                              type="submit"
                              onClick={() => {


                                this.taskProgressChecker("end");
                              }}
                            >
                              {" "}
                              <i className="fa fa-clock" /> End Task
                            </Button>
                          </Col>
                        </Row>
                      )}
                      {this.state.taskUnderEdit.start &&
                        this.state.taskUnderEdit.end && (
                          <Row>
                            <Col lg="2" md="3" sm="4">
                              <Label>
                                {"Task End Timestamp (" +
                                  this.timezoneShortCode(
                                    this.props.currentLocation &&
                                    this.props.currentLocation.timezone,
                                    this.state.taskUnderEdit.end
                                  ) +
                                  ")"}
                              </Label>
                            </Col>
                            <Col className={"task-form"} lg="10" md="9" sm="8">
                              <DatePicker
                                placeholderText={"MM/DD/YYYY HH:MM AP"}
                                disabled
                                className={
                                  this.state.errors &&
                                  this.state.errors.end &&
                                  "is-invalid"
                                }
                                selected={this.state.taskUnderEdit.end}
                                onChange={(date) => {
                                  let s = { ...this.state.taskUnderEdit };
                                  s.end = date;
                                  // if the date is invalid because the user cleared the time component, calling setState will result in infinite recursion
                                  if (date === null || !isNaN(date.getTime())) {
                                    this.setState({ taskUnderEdit: s });
                                  }
                                }}
                                timeInputLabel="Time:"
                                dateFormat="MM/dd/yyyy h:mm a"
                                showTimeInput
                                shouldCloseOnSelect={false}
                              />
                              <FormFeedback
                                className={"d-block"}
                                style={{ marginLeft: 0 }}
                              >
                                {this.state.errors && this.state.errors.end}
                              </FormFeedback>
                            </Col>
                          </Row>
                        )}
                    </div>
                  </CardBody>
                </Card>
                <div className="mb-3 text-right">
                  {/* <Button
                    hidden={this.state.taskUnderEdit.end}
                    className={"float-left"}
                    color="danger"
                    onClick={() => {
                      this.setState({
                        cancelTask: this.state.taskUnderEdit,
                        cancelModalOpen: true,
                      });
                    }}
                  >
                    Cancel Task
                  </Button>{" "} */}
                  &nbsp;
                  {!this.props.disabled && (
                    <Button
                      className="float-left"
                      color="danger"
                      onClick={() => this.cancelEdit()}
                    >
                      Cancel Changes
                    </Button>)}
                  &nbsp;&nbsp;
                  <Button
                    color="primary"
                    onClick={() => {
                      this.setCloseVariable(false);
                    }
                    }
                    type="submit"
                  >
                    Save Changes
                  </Button>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Button
                    color="primary"
                    type="button"
                    onClick={(e) => {
                      if (this.state.taskUnderEdit.end) {
                        confirmAlert({
                          title: "Confirm Complete",
                          message: `Please confirm all fields to complete this task have been populated before closing?`,
                          buttons: [
                            {
                              label: "Return",
                              className: "btn danger",
                              onClick: () => {
                                return;
                              },
                            },
                            {
                              label: "Proceed",
                              className: "btn btn-primary",
                              onClick: () => {
                                // this.setCloseVariable(true);
                                this.handleSubmit(e, true);
                                // this.setState({ saveclick:  true});
                                // this.props.setIsDisable(false);
                                // this.setCloseVariable(true);
                                //this.onSubmit(true);
                              },
                            },
                          ],
                          closeOnEscape: false,
                          closeOnClickOutside: false,
                          overlayClassName: "overlay-custom-class-name",
                        })
                      }
                      else {
                        this.handleSubmit(e, true);


                      }

                    }
                    }
                  >
                    {this.state.taskUnderEdit.end ? "Complete" : " Save & Close"}
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </Loader>
      </div>
    );
  }
  setCloseVariable(val) {
    // if (this.state.taskUnderEdit.start === null) {
    //   this.setState({ taskProgressTypeChecker: 'accept' });
    // }
    this.setState({ closeCheck: val });
  }
  // getButtonState(){
  //   if(this.state.taskUnderEdit.start === undefined || this.state.taskUnderEdit.start === null || this.state.taskUnderEdit.type === undefined || this.state.taskUnderEdit.type === null || this.state.taskUnderEdit.asset === null || this.state.taskUnderEdit.asset === undefined || this.state.taskUnderEdit.carrier === undefined || this.state.taskUnderEdit.carrier === null || this.state.taskUnderEdit.moveTo === null || this.state.taskUnderEdit.moveTo === undefined){
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }
  getAssetType() {
    if (
      this.state.taskUnderEdit.asset &&
      this.state.taskUnderEdit.asset.equipment
    ) {
      return this.state.taskUnderEdit.asset.equipment.name;
    } else if (this.state.taskUnderEdit.assetType) {
      return this.state.taskUnderEdit.assetType.name;
    } else if (this.state.defaultEquipmentType) {
      return this.state.defaultEquipmentType.name;
    } else {
      return "";
    }
  }
}
TaskInProgress.propTypes = {
  currentLocation: PropTypes.object,
  company: PropTypes.object,
};
export default TaskInProgress;
